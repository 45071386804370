import React from "react";
import {Grid} from "@material-ui/core";
import {useTranslationLabel} from "../../../hooks/useTranslation";
import {Props, ValidatePart} from "./00_PartProps";
import SoleSelectionComponent from "../primitives/SoleSelectionComponent";
import SoleStringComponent from "../primitives/SoleStringComponent";
import {HidableComponent} from "../../hoc/Hidable";
import {isMobile} from "react-device-detect";

export const validateClassDetailsSubject:ValidatePart = ({sole, getLabel}) => {
    if (sole.subject === undefined) {
        return getLabel('ui.plan.missing_details_subject');
    } else if (sole.grade === undefined) {
        return getLabel('ui.plan.missing_details_grade');
    }
    return null;
};

export const ClassDetailsSubject: React.FC<Props> = ({props}) => {

    const getLabel = useTranslationLabel();

    return (<>
        <Grid container justifyContent="center">
            <Grid item {...props.gridSizes}>
                <SoleSelectionComponent key={`subject-${props.sole.subject}`}
                                        sole={props.sole}
                                        setSole={props.setSole}
                                        jsonKey="subject"
                                        rdn="session.subject"
                />
            </Grid>
            <Grid item>
                <div style={{height:"1em", width:"1em"}}/>
            </Grid>
            <Grid item {...props.gridSizes}>
                <SoleSelectionComponent key={`grade-${props.sole.grade}`}
                                        sole={props.sole}
                                        setSole={props.setSole}
                                        jsonKey="grade"
                                        rdn="session.grade"
                />
            </Grid>
        </Grid>
        <Grid container justifyContent="center">
            <Grid item {...props.gridSizes}>
                <SoleStringComponent
                    label={getLabel('ui.plan.class_label')}
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="tag"
                    rdn="session.tag"
                />
            </Grid>
        </Grid>
    </>)
};

