import {combineReducers} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {configureStore} from "@reduxjs/toolkit";
import logger from "redux-logger";
import rootSaga from "../saga";
import {sessionSlice} from "./sessionSlice";
import {solesSlice} from "./solesSlice";
import {constraintSlice} from "./constraintSlice";
import {dataPointSlice} from "./dataPointSlice";
import {myQuestionsSlice} from "./myQuestionsSlice";
import {questionAddSlice} from "./questionAddSlice";
import {questionSearchSlice} from "./questionSearchSlice";
import {reportSlice} from "./reportSlice";
import {resourceSlice} from "./resourceSlice";
import {tagsSlice} from "./tagsSlice";
import {textSlice} from "./textSlice";
import {resetUser, userSlice} from "./userSlice";

const sagaMiddleware = createSagaMiddleware();

const appReducer = combineReducers({
    text: textSlice.reducer,
    session: sessionSlice.reducer,
    user: userSlice.reducer,
    soles: solesSlice.reducer,
    dataPoints: dataPointSlice.reducer,
    constraints: constraintSlice.reducer,
    myQuestions: myQuestionsSlice.reducer,
    questionAdd: questionAddSlice.reducer,
    questionsSearch: questionSearchSlice.reducer,
    resources: resourceSlice.reducer,
    report: reportSlice.reducer,
    tags: tagsSlice.reducer
});

/**
 * https://stackoverflow.com/a/61943631
 */
const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any): any => {
    if (action.type === resetUser.type) {
        state = undefined;
    }

    return appReducer(state, action);
};

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        const environment = process.env.NODE_ENV;
        let middleware = getDefaultMiddleware({serializableCheck: false});
//        if (environment === 'development') {
//            middleware = middleware.concat(logger);
//        }
        middleware = middleware.concat(sagaMiddleware);
        return middleware;
    }
});

sagaMiddleware.run(rootSaga);
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export default store;
