import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import {KeyboardDatePicker, KeyboardTimePicker} from "@material-ui/pickers";
import {useTranslationLabel} from "../../../hooks/useTranslation";
import SoleNumberComponent from "../primitives/SoleNumberComponent";
import {ValidatePart, Props} from "./00_PartProps";
import {isMobile} from "react-device-detect";

export const validatePlannedDate:ValidatePart = (_props) => {
    return null;
};

export const PlannedDatePart: React.FC<Props> = ({props}) => {

    const getLabel = useTranslationLabel();

    const handleDateTimeChange = (date: Date | null) => {
        if (date) {
            props.setSole({...props.sole, plannedDate: date});
        }
    };

    const xs = isMobile ? 12 : 5;

    return (
        <>
            <Grid item xs={xs}>
                <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label={getLabel('ui.plan.what_is_the_planned_date?')}
                    format="MM/dd/yyyy"
                    fullWidth
                    value={props.sole.plannedDate}
                    onChange={handleDateTimeChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date'
                    }}
                />
            </Grid>

            <Grid item xs={xs}>
                <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label={getLabel('ui.plan.what_is_the_planned_time?')}
                    fullWidth
                    value={props.sole.plannedDate}
                    onChange={handleDateTimeChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change time'
                    }}
                />
            </Grid>

            <Grid item xs={xs}>
                <SoleNumberComponent
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="plannedDuration"
                    rdn="session.planned_duration"
                    isSlider={true}
                />
            </Grid>
        </>
    )
};
