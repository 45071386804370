import {SoleText} from "../data/SoleText";

export default [
    new SoleText('ui.save', 'en_US', 'Save'),
    new SoleText('ui.delete', 'en_US', 'Delete'),
    new SoleText('ui.approve', 'en_US', 'Approve'),
    new SoleText('ui.reject', 'en_US', 'Reject'),
    new SoleText('ui.cancel', 'en_US', 'Cancel'),
    new SoleText('ui.yes', 'en_US', 'Yes'),
    new SoleText('ui.no', 'en_US', 'No'),
    new SoleText('ui.search', 'en_US', 'Student Search'),
    new SoleText('ui.search_by_keyword', 'en_US', 'Search by keyword'),
    new SoleText('ui.search_questions', 'en_US', 'SEARCH QUESTIONS'),
    new SoleText('ui.ok', 'en_US', 'OK'),
    new SoleText('ui.download', 'en_US', 'Download'),
    new SoleText('ui.undo', 'en_US', 'Undo'),
    new SoleText('ui.favorite', 'en_US', 'Favorite'),
    new SoleText('ui.unfavorite', 'en_US', 'Unfavorite'),
    new SoleText('ui.contact_us', 'en_US', 'Contact Us'),
    new SoleText('ui.support', 'en_US', 'support@startsole.org'),
    new SoleText('ui.add_a_question', 'en_US', 'Add a Question'),
    new SoleText('ui.adding_question', 'en_US', 'Adding question...'),
    new SoleText('ui.loading_tags', 'en_US', 'Loading tags...'),
    new SoleText('ui.loading_questions', 'en_US', 'Loading questions...'),
    new SoleText('ui.enter_your_question', 'en_US', 'Enter your question here'),
    new SoleText('ui.submit_question', 'en_US', 'Submit Question'),
    new SoleText('ui.back', 'en_US', 'Back'),
    new SoleText('ui.next', 'en_US', 'Next'),
    new SoleText('ui.close', 'en_US', 'Close'),
    new SoleText('ui.continue', 'en_US', 'Continue'),
    new SoleText('ui.copy', 'en_US', 'copy'),
    new SoleText('ui.copied_to_clipboard', 'en_US', 'Copied to Clipboard'),
    new SoleText('ui.how_to_sole', 'en_US', 'How to SOLE?'),
    new SoleText('ui.history_of_startsole', 'en_US', 'History of StartSOLE'),
    new SoleText('ui.loading', 'en_US', 'Loading...'),
    new SoleText('ui.my_profile', 'en_US', 'My Profile'),
    new SoleText('ui.question', 'en_US', 'Question'),
    new SoleText('ui.admin', 'en_US', 'Admin'),
    new SoleText('ui.my_questions', 'en_US', 'My Questions'),
    new SoleText('ui.my_soles', 'en_US', 'My SOLEs'),
    new SoleText('ui.my_community', 'en_US', 'My Community'),
    new SoleText('ui.sole_and_udl', 'en_US', 'SOLE + UDL'),
    new SoleText('ui.plan_a_lesson', 'en_US', 'Plan a Lesson'),
    new SoleText('ui.teacher_resources', 'en_US', 'Teacher Resources'),
    new SoleText('ui.virtual', 'en_US', 'Virtual'),
    new SoleText('ui.explore_questions', 'en_US', 'Explore Questions'),
    new SoleText('ui.home.lets_get_started', 'en_US', 'Let\'s Get Started'),
    new SoleText('ui.home.welcome_card_text', 'en_US', 'We can customize a brief message here for different rings or whatever'),
    new SoleText('ui.home.start_tour', 'en_US', 'Start Tour'),
    new SoleText('ui.admin.educators', 'en_US', 'Educators'),
    new SoleText('ui.admin.map', 'en_US', 'Map'),
    new SoleText('ui.admin.soles', 'en_US', 'SOLEs'),
    new SoleText('ui.admin.schools', 'en_US', 'Schools'),
    new SoleText('ui.admin.loading_schools', 'en_US', 'Loading Schools...'),
    new SoleText('ui.admin.loading_educators', 'en_US', 'Loading Educators...'),
    new SoleText('ui.admin.loading_email settings', 'en_US', 'Loading Email Settings...'),
    new SoleText('ui.admin.loading_question', 'en_US', 'Loading Question...'),
    new SoleText('ui.admin.soles_by_grade', 'en_US', 'SOLEs By Grade'),
    new SoleText('ui.admin.soles_by_subject', 'en_US', 'SOLEs By Subject'),
    new SoleText('ui.admin.users_by_subject', 'en_US', 'Educators By Subject'),
    new SoleText('ui.admin.users_by_grade', 'en_US', 'Educators By Grade'),
    new SoleText('ui.admin.users_per_month_title', 'en_US', 'New User Signups'),
    new SoleText('ui.admin.users_per_month_x_axis', 'en_US', 'month'),
    new SoleText('ui.admin.users_per_month_y_axis', 'en_US', 'new users'),
    new SoleText('ui.admin.loading_soles', 'en_US', 'Loading SOLEs...'),
    new SoleText('ui.learn_more', 'en_US', 'Learn More'),
    new SoleText('ui.plan.big_question_and_standard', 'en_US', 'Big Question'),
    new SoleText('ui.plan.what_is_your_big_question', 'en_US', 'What is your big question?'),
    new SoleText('ui.plan.setup_public_token', 'en_US', 'Setup public link?'),
    new SoleText('ui.profile.about_me', 'en_US', 'About Me'),
    new SoleText('ui.profile.location', 'en_US', 'Location'),
    new SoleText('ui.profile.email', 'en_US', 'Email'),
    new SoleText('ui.profile.certificates', 'en_US', 'Certificates'),
    new SoleText('ui.profile.password', 'en_US', 'Password'),
    new SoleText('ui.profile.trainer_materials', 'en_US', 'Trainer'),
    new SoleText('ui.profile.certificate_explanation', 'en_US', 'Educators earn points for their work with StartSOLE.  You can download your certificates here.'),
    new SoleText('ui.profile.no_certificates', 'en_US', 'You haven\'t earned any certificates... yet.'),
    new SoleText('ui.profile.progress_caption', 'en_US', '/2000 points to your next certificate'),
    new SoleText('ui.profile.trainer_explanation', 'en_US', 'Please remember to share any copied content with support@startsole.org so we can learn from your experience.'),
    new SoleText('ui.profile.trainer_download_email_icon', 'en_US', 'Download Email Signature Logo'),
    new SoleText('ui.profile.about_first_name', 'en_US', 'First Name'),
    new SoleText('ui.profile.about_last_name', 'en_US', 'Last Name'),
    new SoleText('ui.profile.about_role', 'en_US', 'Role'),
    new SoleText('ui.profile.about_grades', 'en_US', 'Grades'),
    new SoleText('ui.profile.about_subjects', 'en_US', 'Subjects'),
    new SoleText('ui.profile.location_add', 'en_US', 'Add your location'),
    new SoleText('ui.profile.location_update', 'en_US', 'Change your location'),
    new SoleText('ui.profile.location_explanation', 'en_US', 'We use your location to customize your experience and deliver content in your language if available.'),
    new SoleText('ui.profile.complete_header', 'en_US', 'Complete your Profile'),
    new SoleText('ui.profile.need_more_info', 'en_US', 'We need a little more information to customize your experience, please complete the fields below.'),
    new SoleText('ui.profile.new_password', 'en_US', 'New password'),
    new SoleText('ui.profile.update_password', 'en_US', 'Update password'),
    new SoleText('ui.profile.current_password', 'en_US', 'Current password'),
    new SoleText('ui.profile.password_explanation', 'en_US', 'Use the form below to reset your password.  If you are having difficulty and need support feel free to contact: '),
    new SoleText('ui.profile.password_updated', 'en_US', 'Password successfully updated'),
    new SoleText('ui.profile.privacy_header', 'en_US', 'StartSOLE Privacy Policy'),
    new SoleText('ui.profile.privacy_body', 'en_US', 'Your privacy is important to us.  We use the information on this page to customize content for your SOLE experience and deliver big questions aligned to your standards if they are available.  We use cookies to cache your data personalise content.  We don\'t share your information with advertisers.  If you\'d like to read our complete privacy policy you can click the link below.'),
    new SoleText('ui.profile.privacy_button', 'en_US', 'Privacy Policy'),
    new SoleText('ui.questions.all_subjects', 'en_US', 'All Subjects'),
    new SoleText('ui.questions.mine', 'en_US', 'MINE'),
    new SoleText('ui.questions.saved', 'en_US', 'SAVED'),
    new SoleText('ui.questions.nothing_here', 'en_US', 'Nothing here...yet. Share your own questions with the SOLE community.'),
    new SoleText('ui.profile.email_explanation', 'en_US', 'Use the checkboxes below to manage when you will receive emails from us based on your actions with StartSOLE.'),
    new SoleText('ui.plan.standard', 'en_US', 'Please select a standard for this question'),
    new SoleText('ui.plan.class_details', 'en_US', 'Class Details'),
    new SoleText('ui.plan.subject', 'en_US', 'Subject and Grade'),
    new SoleText('ui.plan.materials', 'en_US', 'Materials'),
    new SoleText('ui.plan.objective', 'en_US', 'Objectives'),
    new SoleText('ui.plan.observations', 'en_US', 'Observations'),
    new SoleText('ui.plan.what_is_the_planned_date?', 'en_US', 'What is the planned date?'),
    new SoleText('ui.plan.what_is_the_planned_time?', 'en_US', 'What is the planned time?'),
    new SoleText('ui.plan.how_will_you_manage', 'en_US', 'How will you manage this SOLE?'),
    new SoleText('ui.plan.objective_logistics', 'en_US', 'Objectives & Logistics'),
    new SoleText('ui.plan.missing_question', 'en_US', 'Please add a question before saving'),
    new SoleText('ui.plan.missing_details', 'en_US', 'Please complete your class details before saving'),
    new SoleText('ui.plan.missing_details_subject', 'en_US', 'Please pick a subject for your SOLE'),
    new SoleText('ui.plan.missing_details_grade', 'en_US', 'Please pick a grade for your SOLE'),
    new SoleText('ui.plan.missing_logistics', 'en_US', 'Please complete your objectives & logistics before saving'),
    new SoleText('ui.plan.missing_logistics_objective', 'en_US', 'Please select an objective for your SOLE'),
    new SoleText('ui.plan.missing_logistics_behaviors', 'en_US', 'Please select at least one behavior to focus on during this SOLE'),
    new SoleText('ui.public.title', 'en_US', 'Custom Exploration'),
    new SoleText('ui.public.copy_link', 'en_US', 'Copy the link below to send to the participants:'),
    new SoleText('ui.question.plan_a_lesson', 'en_US', 'Plan a lesson'),
    new SoleText('ui.question.virtual_sole', 'en_US', 'Virtual SOLE'),
    new SoleText('ui.question.added_to_favorites', 'en_US', 'Added to Favorites'),
    new SoleText('ui.question.removed_from_favorites', 'en_US', 'Removed from Favorites'),
    new SoleText('ui.question.resources', 'en_US', 'Related Resources'),
    new SoleText('ui.reflect.add_photos', 'en_US', 'Add Photos'),
    new SoleText('ui.reflect.mobile.drop_photos', 'en_US', 'Click here to add photos'),
    new SoleText('ui.reflect.drop_photos', 'en_US', 'Drag and drop photos here or click to browse'),
    new SoleText('ui.reflect.review_photos', 'en_US', 'Review Photos'),
    new SoleText('ui.reflect.objective_question', 'en_US', ' What was your objective for this SOLE?'),
    new SoleText('ui.reflect.objective_question_suffix', 'en_US', ' was your objective for this SOLE.'),
    new SoleText('ui.reflect.objective_question_achieved', 'en_US', 'Do you think you achieved this objective?'),
    new SoleText('ui.reflect_objectives', 'en_US', 'Objectives'),
    new SoleText('ui.reflect_ratings', 'en_US', 'Ratings'),
    new SoleText('ui.reflect_ratings', 'en_US', 'Ratings'),
    new SoleText('ui.reflect_final', 'en_US', 'Closing'),
    new SoleText('ui.reflect.missing_photos', 'en_US', 'Please upload one or more photos documenting this SOLE'),
    new SoleText('ui.reflect.missing_content_objective', 'en_US', 'Please select a content objective for this SOLE'),
    new SoleText('ui.reflect.missing_content_objective_achieved', 'en_US', 'Please let us know if you think you achieved your objective for this SOLE'),
    new SoleText('ui.reflect.missing_type_of_thinking', 'en_US', 'Please let us know what type of thinking most participants were using in this SOLE'),
    new SoleText('ui.reflect.missing_ground_rules', 'en_US', 'Please let us know if participants followed the ground rules for this SOLE'),
    new SoleText('ui.reflect.missing_needs_help', 'en_US', 'Please let us know if you need assistance'),
    new SoleText('ui.reflect_request_help', 'en_US', 'Request Help'),
    new SoleText('ui.reflect_why_do_you_think_this', 'en_US', 'Why do you think this?'),
    new SoleText('ui.reflect_actively_engaged', 'en_US', 'What percentage of students were actively engaged?'),
    new SoleText('ui.reflect_shared_responsibility', 'en_US', 'What percentage of students worked effectively and respectfully together and shared responsibility?'),
    new SoleText('ui.reflect_technology_effectively', 'en_US', 'What percentage of student groups used technology effectively?'),
    new SoleText('ui.reflect_articulate_listen', 'en_US', 'What percentage of students articulated thoughts and listened effectively?'),
    new SoleText('reflection.ground_rules', 'en_US', 'Did students appreciate and follow the ground rules?'),
    new SoleText('reflection.need_help', 'en_US', 'Do you need further assistance?'),
    new SoleText('reflection.help_text', 'en_US', 'I need help with...'),
    new SoleText('ui.resources.shared_by', 'en_US', 'Shared by'),
    new SoleText('ui.soles.loading_soles', 'en_US', 'Loading SOLEs...'),
    new SoleText('ui.soles.saving_sole', 'en_US', 'Saving SOLE...'),
    new SoleText('ui.soles.copying_sole', 'en_US', 'Copying SOLE...'),
    new SoleText('ui.soles.planned', 'en_US', 'Planned'),
    new SoleText('ui.soles.reflect', 'en_US', 'Reflect'),
    new SoleText('ui.soles.completed', 'en_US', 'Completed'),
    new SoleText('ui.sole-card.virtual', 'en_US', 'Virtual'),
    new SoleText('ui.sole-card.delete_title', 'en_US', 'Are you sure you want to delete this SOLE?'),
    new SoleText('ui.sole-card.delete_body', 'en_US', 'Deleting this SOLE will remove all associated data and clear this SOLE from your list.  Are you sure you want to proceed?'),
    new SoleText('ui.sole-card.download_plan', 'en_US', 'Download Plan'),
    new SoleText('ui.sole-card.download_summary', 'en_US', 'Download Summary'),
    new SoleText('ui.sole-card.reflect', 'en_US', 'Reflect'),
    new SoleText('ui.sole-card.edit', 'en_US', 'Edit Plan'),
    new SoleText('ui.search.title', 'en_US', 'Welcome to Student Search'),
    new SoleText('ui.search.explanation_details', 'en_US', 'StartSOLE has partnered with credible third party content providers. StartSOLE Student Search allows you to quickly create a custom content search environment for your students that returns credible, relevant, grade-level appropriate content.'),
    new SoleText('ui.search.need_a_question', 'en_US', 'To get started you\'ll need a Big Question. Use one of the options below to find or create a question, then click on the blue \'Student Search\' button to setup your link for students.'),
    new SoleText('ui.virtual.title', 'en_US', 'Welcome to StartSOLE Virtual'),
    new SoleText('ui.virtual.ready_to_go', 'en_US', 'You\'re ready to go virtual!'),
    new SoleText('ui.virtual.button_text', 'en_US', 'I\'m Interested'),
    new SoleText('ui.virtual.button_setup', 'en_US', 'Setup Virtual SOLE'),
    new SoleText('ui.virtual.button_instructions', 'en_US', 'Click the button below to create your virtual SOLE session.'),
    new SoleText('ui.virtual.click_the_button_explanation', 'en_US', 'Click the button below to start your virtual SOLE:'),
    new SoleText('ui.virtual.copy_link', 'en_US', 'Copy the link below to send to the participants:'),
    new SoleText('ui.virtual.explanation', 'en_US', 'StartSOLE Virtual is a tool we are piloting to allow educators to facilitate a SOLE session remotely.  Looks like it\'s not available in your region yet, but if you\'re interested in learning more please let us know using the form below:'),
    new SoleText('ui.virtual.get_started', 'en_US', 'Get Started'),
    new SoleText('ui.virtual.share_to_classroom', 'en_US', 'Share to Google Classroom'),
    new SoleText('ui.virtual.explanation_details', 'en_US', 'StartSOLE Virtual allows you to facilitate a SOLE session remotely with useful features like chat, webcam sharing, multi-user whiteboards, screen sharing, breakout rooms for small group work.'),
    new SoleText('ui.virtual.need_a_question', 'en_US', 'To get started you\'ll need a Big Question. Use one of the options below to find or create a question, then click on the blue \'Virtual StartSOLE\' button to setup your session.'),
    new SoleText('ui.virtual.constraint.guestPolicy', 'en_US', 'Moderators must approve participants'),
    new SoleText('ui.virtual.constraint.lockSettingsDisablePrivateChat', 'en_US', 'Disable Private Chat for participants'),
    new SoleText('ui.virtual.constraint.lockSettingsDisableNote', 'en_US', 'Disable Shared Notes for participants'),
    new SoleText('ui.plan.class_label', 'en_US', 'Class label (optional)'),
    new SoleText('error.min.default', 'en_US', 'This value is too low'),
    new SoleText('error.min.session.planned_duration', 'en_US', 'SOLEs should be at least 30 minutes'),
    new SoleText('error.min.session.num_devices', 'en_US', 'A SOLE requires at least 1 device'),
    new SoleText('error.min.session.num_students', 'en_US', 'A SOLE requires at least 3 students'),
    new SoleText('error.min.session.num_groups', 'en_US', 'A SOLE requires at least 2 groups'),
    new SoleText('error.fetching_questions', 'en_US', 'Something went wrong fetching your questions'),
    new SoleText('ui.join', 'en_US', 'Join'),
    new SoleText('ui.enter_code', 'en_US', 'Enter your code to join a SOLE session'),
    new SoleText('ui.login.logging_in', 'en_US', 'Logging In...'),
    new SoleText('ui.login.sign_in', 'en_US', 'Sign In'),
    new SoleText('ui.login.google_unavailable', 'en_US', 'Google login is unavailable'),
    new SoleText('ui.login.or', 'en_US', 'or'),
    new SoleText('ui.login.forgot_password', 'en_US', 'Forgot password?'),
    new SoleText('ui.login.no_account', 'en_US', 'Don\'t have an account? Sign Up'),
    new SoleText('ui.login.reset_password', 'en_US', 'Reset Password'),
    new SoleText('ui.login.reset_password_confirmation', 'en_US', 'Enter your email below and we\'ll send you a link to reset your password.'),
    new SoleText('ui.login.reset_password_cancel', 'en_US', 'Send Reset Email'),
    new SoleText('ui.login.reset_password_success', 'en_US', 'Password reset!'),
    new SoleText('ui.register', 'en_US', 'Register'),
    new SoleText('ui.register.sign_up', 'en_US', 'Sign Up'),
    new SoleText('ui.register.register_with_google', 'en_US', 'Register with your Google Account'),
    new SoleText('ui.register.already_have_account', 'en_US', 'Already have an account? Login'),
    new SoleText('ui.maintenance.title', 'en_US', 'Upcoming Maintenance'),
    new SoleText('ui.maintenance.description', 'en_US', 'We will be performing scheduled maintenance on our website and mobile apps.  We apologize for any inconvenience.  Our tools will be unavailable from '),
    new SoleText('all', 'en_US', 'All Grades', '', ''),
    new SoleText('edu.k', 'en_US', 'Kindergarten', '', ''),
    new SoleText('edu.1', 'en_US', 'First Grade', '', ''),
    new SoleText('edu.2', 'en_US', 'Second Grade', '', ''),
    new SoleText('edu.3', 'en_US', 'Third Grade', '', ''),
    new SoleText('edu.4', 'en_US', 'Fourth Grade', '', ''),
    new SoleText('edu.5', 'en_US', 'Fifth Grade', '', ''),
    new SoleText('edu.6', 'en_US', 'Sixth Grade', '', ''),
    new SoleText('edu.7', 'en_US', 'Seventh Grade', '', ''),
    new SoleText('edu.8', 'en_US', 'Eighth Grade', '', ''),
    new SoleText('edu.9', 'en_US', 'Ninth Grade', '', ''),
    new SoleText('edu.10', 'en_US', 'Tenth Grade', '', ''),
    new SoleText('edu.11', 'en_US', 'Eleventh Grade', '', ''),
    new SoleText('edu.12', 'en_US', 'Twelfth Grade', '', ''),
    new SoleText('edu.adulteducation', 'en_US', 'Adult Education', '', ''),
    new SoleText('top.careereducation', 'en_US', 'Career Education', '', ''),
    new SoleText('top.engineering', 'en_US', 'Engineering', '', ''),
    new SoleText('top.english', 'en_US', 'English', '', ''),
    new SoleText('top.englishlanguagedevelopment', 'en_US', 'English Language Development', '', ''),
    new SoleText('top.financialliteracy', 'en_US', 'Financial Literacy', '', ''),
    new SoleText('top.foreignlanguage', 'en_US', 'Foreign Languages', '', ''),
    new SoleText('top.health', 'en_US', 'Health', '', ''),
    new SoleText('top.libmedia-infolit', 'en_US', 'Library/Media Information Literacy', '', ''),
    new SoleText('top.lifeskills', 'en_US', 'Life Skills', '', ''),
    new SoleText('top.math', 'en_US', 'Math', '', ''),
    new SoleText('top.physicaleducation', 'en_US', 'Physical Education', '', ''),
    new SoleText('top.science', 'en_US', 'Science', '', ''),
    new SoleText('top.socialstudies', 'en_US', 'Social Studies', '', ''),
    new SoleText('top.technology', 'en_US', 'Technology', '', ''),
    new SoleText('top.thearts', 'en_US', 'The Arts', '', ''),
    new SoleText('edu.pre-k', 'en_US', 'Preschool', '', ''),
    new SoleText('session.groupsharing.behaviour', 'en_US', 'Behavioral concerns', '', ''),
    new SoleText('session.groupsharing.time', 'en_US', 'Time management concerns', '', ''),
    new SoleText('session.groupsharing.originality', 'en_US', 'Originality concerns', '', ''),
    new SoleText('session.groupsharing.space', 'en_US', 'Physical space limitations', '', ''),
    new SoleText('session.groupsharing.other', 'en_US', 'Other', '', ''),
    new SoleText('objective.content.standard', 'en_US', 'Introducing students to a new concept', '', ''),
    new SoleText('objective.content.prior', 'en_US', 'Helping students connect prior knowledge to a current topic', '', ''),
    new SoleText('objective.content.explore', 'en_US', 'Allowing students to discover and explore interests related to a topic', '', ''),
    new SoleText('objective.content.deepen', 'en_US', 'Helping student deepen understanding of a current topic', '', ''),
    new SoleText('objective.content.review', 'en_US', 'Reviewing knowledge from a current unit', '', ''),
    new SoleText('objective.content.assess', 'en_US', 'Assessing student understanding of a current topic', '', ''),
    new SoleText('objective.process.collaboration_skills', 'en_US', 'I want my students to improve collaboration skills', '', ''),
    new SoleText('objective.process.communication_skills', 'en_US', 'I want students to work on communication skills', '', ''),
    new SoleText('objective.process.deeper_learning', 'en_US', 'I want deeper learning in my classroom (Blooms taxonomy)', '', ''),
    new SoleText('objective.process.engagement', 'en_US', 'I want to increase student engagement', '', ''),
    new SoleText('material.writing_tools', 'en_US', 'Writing tools', 'Writing tools', ''),
    new SoleText('material.poster_paper', 'en_US', 'Poster paper', 'Poster paper', ''),
    new SoleText('material.physical', 'en_US', 'Physical resources', 'Physical resources (books, supporting content)', 'Physical resources (books, supporting content)'),
    new SoleText('material.sole_organizer', 'en_US', 'SOLE Student Organizer', 'SOLE student organizer', ''),
    new SoleText('material.other', 'en_US', 'Other materials', 'Other materials', ''),
    new SoleText('reflect.agree.strongly_agree', 'en_US', 'Strongly Agree', '', ''),
    new SoleText('reflect.agree.agree', 'en_US', 'Agree', '', ''),
    new SoleText('reflect.agree.disagree', 'en_US', 'Disagree', '', ''),
    new SoleText('reflect.agree.strongly_disagree', 'en_US', 'Strongly Disagree', '', ''),
    new SoleText('session.observation.collaborating', 'en_US', 'Collaborating', '', ''),
    new SoleText('session.observation.technology', 'en_US', 'Using technology well', '', ''),
    new SoleText('session.observation.respectful', 'en_US', 'Respectful dialogue and debate', '', ''),
    new SoleText('session.observation.desire', 'en_US', 'Desire to learn content', '', ''),
    new SoleText('session.observation.vocabulary', 'en_US', 'Appropriate use of vocabulary', '', ''),
    new SoleText('session.observation.help_learn', 'en_US', 'Students teaching students', 'Lesson Effect', ''),
    new SoleText('session.observation.help_technology', 'en_US', 'Helping peers with technology', '', ''),
    new SoleText('session.observation.keyboard', 'en_US', 'Difficulty using keyboard', '', ''),
    new SoleText('session.observation.writing', 'en_US', 'Struggling with writing', '', ''),
    new SoleText('session.observation.reading', 'en_US', 'Struggling with reading', '', ''),
    new SoleText('session.observation.turns', 'en_US', 'Not taking turns', '', ''),
    new SoleText('session.observation.imbalanced', 'en_US', 'Imbalanced groups', '', ''),
    new SoleText('session.observation.plagiarizing', 'en_US', 'Plagiarizing information', '', ''),
    new SoleText('session.observation.one_source', 'en_US', 'Using only one source', '', ''),
    new SoleText('session.observation.complaining', 'en_US', 'Complaining', '', ''),
    new SoleText('session.observation.off_task', 'en_US', 'Off Task', '', ''),
    new SoleText('session.observation.inaccurate', 'en_US', 'Inaccurate or irrelevant information', '', ''),
    new SoleText('session.observation.ohmeansjobs_collaborating', 'en_US', 'Teamwork/Collaboration', '', ''),
    new SoleText('session.observation.ohmeansjobs_critical_thinking', 'en_US', 'Critical Thinking/Problem Solving', '', ''),
    new SoleText('session.observation.ohmeansjobs_leadership', 'en_US', 'Leadership', '', ''),
    new SoleText('session.observation.ohmeansjobs_creativity', 'en_US', 'Creativity/Innovation', '', ''),
    new SoleText('session.observation.ohmeansjobs_communication', 'en_US', 'Oral/Written Communication', '', ''),
    new SoleText('session.observation.ohmeansjobs_intercultural_fluency', 'en_US', 'Global/Intercultural Fluency', '', ''),
    new SoleText('session.observation.engagement', 'en_US', 'Engagement', '', ''),
    new SoleText('session.observation.other', 'en_US', 'Other observation', '', ''),
    new SoleText('session.observation.group.presentation', 'en_US', 'Group Presentation', 'Group Presentation', ''),
    new SoleText('reflection.type_of_thinking.recall', 'en_US', 'Recall & Reproduction', '', ''),
    new SoleText('reflection.type_of_thinking.skill', 'en_US', 'Skill/Concept', '', ''),
    new SoleText('reflection.type_of_thinking.short_strategic', 'en_US', 'Short Term Strategic', '', ''),
    new SoleText('reflection.type_of_thinking.long_strategic', 'en_US', 'Long Term Strategic', '', ''),
    new SoleText('reflectionReminders', 'en_US', 'Reflection Reminders', '', ''),
    new SoleText('planningDoc', 'en_US', 'Planning Documents', '', ''),
    new SoleText('summaryDoc', 'en_US', 'Summary Documents', '', ''),
    new SoleText('ceuDoc', 'en_US', 'CEU Documents', '', ''),
    new SoleText('questionTips', 'en_US', 'Question Tips', '', ''),
    new SoleText('virtualEnded', 'en_US', 'Virtual Sole Ended', '', ''),
    new SoleText('virtualRecordings', 'en_US', 'Virtual SOLE Recordings', '', ''),
    new SoleText('user.role.teacher', 'en_US', 'Teacher', '', ''),
    new SoleText('user.role.preservice_teacher', 'en_US', 'Pre-service Teacher', '', ''),
    new SoleText('user.role.administrator', 'en_US', 'Administrator', '', ''),
    new SoleText('user.role.out_of_school_educator', 'en_US', 'Out of school educator', '', ''),
    new SoleText('user.role.coach', 'en_US', 'Coach', '', ''),
    new SoleText('user.role.parent', 'en_US', 'Parent', '', ''),
    new SoleText('user.role.other', 'en_US', 'Other', '', ''),
    new SoleText('session.target_observations_set.default', 'en_US', 'Default observations', '', ''),
    new SoleText('session.target_observations_set.ohiomeansjobs', 'en_US', 'Observations #1', '', ''),
    new SoleText('session.target_observations_set.xyz', 'en_US', 'Observations #2', '', ''),
    new SoleText('session.planned_duration', 'en_US', 'Duration', 'What is the class duration?', ''),
    new SoleText('session.num_devices', 'en_US', 'Devices', 'How many devices are available?', 'How many internet enabled devices are available?'),
    new SoleText('session.num_students', 'en_US', 'Students', 'How many students?', 'How many students are in this SOLE?'),
    new SoleText('session.num_groups', 'en_US', 'Groups', 'How many groups for this SOLE?', 'Number of groups'),
    new SoleText('session.grade', 'en_US', 'Grade', 'What grade is this SOLE for?', ''),
    new SoleText('session.subject', 'en_US', 'Subject', 'What is the SOLE Subject?', ''),
    new SoleText('session.content_objective', 'en_US', 'Content Objective', 'What is your objective for this SOLE?', 'What is your objective for this SOLE?'),
    new SoleText('session.materials', 'en_US', 'Materials', 'What materials will be used during this SOLE?', ''),
    new SoleText('session.grouporganization', 'en_US', '', 'Will participants be forming their own groups?', ''),
    new SoleText('session.groupsharing', 'en_US', '', 'Will you allow sharing among groups while researching?', ''),
    new SoleText('session.self_assessment', 'en_US', '', 'Will students complete an exit ticket after the SOLE?', ''),
    new SoleText('session.target_observations', 'en_US', 'Observations', 'Pick up to 3 student behaviours you would like to focus on during this SOLE', ''),
    new SoleText('session.reflection.type_of_thinking', 'en_US', 'Type of Thinking', 'What type of thinking were most students using during this SOLE?', ''),
    new SoleText('ui.maintenance.token', 'en_US', '5'), // change this when showing a new message
    new SoleText('ui.plan_type.simple', 'en_US', 'Simple Plan'),
    new SoleText('ui.plan_type.default', 'en_US', 'Default'),
    new SoleText('ui.logout', 'en_US', 'Logout'),

    new SoleText('ui.save', 'es_CO', 'Guardar'),
    new SoleText('ui.delete', 'es_CO', 'Borrar'),
    new SoleText('ui.approve', 'es_CO', 'Aprobar'),
    new SoleText('ui.reject', 'es_CO', 'Rechazar'),
    new SoleText('ui.cancel', 'es_CO', 'Cancelar'),
    new SoleText('ui.yes', 'es_CO', 'Sí'),
    new SoleText('ui.no', 'es_CO', 'No'),
    new SoleText('ui.search', 'es_CO', 'Busqueda de Participantes'),
    new SoleText('ui.search_by_keyword', 'es_CO', 'Búsqueda por palabra clave'),
    new SoleText('ui.search_questions', 'es_CO', 'Buscar preguntas'),
    new SoleText('ui.ok', 'es_CO', 'OK'),
    new SoleText('ui.download', 'es_CO', 'Descargar'),
    new SoleText('ui.undo', 'es_CO', 'Deshacer'),
    new SoleText('ui.favorite', 'es_CO', 'Favorito'),
    new SoleText('ui.unfavorite', 'es_CO', 'No favorito'),
    new SoleText('ui.contact_us', 'es_CO', 'Contáctanos'),
    new SoleText('ui.support', 'es_CO', 'apoyo@startsole.org'),
    new SoleText('ui.add_a_question', 'es_CO', 'Añadir una pregunta'),
    new SoleText('ui.adding_question', 'es_CO', 'Añadiendo pregunta...'),
    new SoleText('ui.loading_tags', 'es_CO', 'Cargando etiquetas...'),
    new SoleText('ui.enter_your_question', 'es_CO', 'Introduce tu pregunta aquí'),
    new SoleText('ui.submit_question', 'es_CO', 'Enviar pregunta'),
    new SoleText('ui.back', 'es_CO', 'Atrás'),
    new SoleText('ui.next', 'es_CO', 'Siguiente'),
    new SoleText('ui.close', 'es_CO', 'Cerrar'),
    new SoleText('ui.continue', 'es_CO', 'Continuar'),
    new SoleText('ui.copy', 'es_CO', 'copiar'),
    new SoleText('ui.copied_to_clipboard', 'es_CO', 'Copiado a Portapapeles'),
    new SoleText('ui.how_to_sole', 'es_CO', '¿Cómo SOLEar?'),
    new SoleText('ui.history_of_startsole', 'es_CO', 'Historia de StartSOLE'),
    new SoleText('ui.loading', 'es_CO', 'Cargando...'),
    new SoleText('ui.my_profile', 'es_CO', 'Mi perfil'),
    new SoleText('ui.question', 'es_CO', 'Pregunta'),
    new SoleText('ui.admin', 'es_CO', 'Admin'),
    new SoleText('ui.my_questions', 'es_CO', 'Mis Preguntas'),
    new SoleText('ui.my_soles', 'es_CO', 'Mis SOLEs'),
    new SoleText('ui.my_community', 'es_CO', 'Mi Comunidad'),
    new SoleText('ui.plan_a_lesson', 'es_CO', 'Planear un SOLE'),
    new SoleText('ui.teacher_resources', 'es_CO', 'Recursos para Educadores'),
    new SoleText('ui.virtual', 'es_CO', 'Virtual'),
    new SoleText('ui.explore_questions', 'es_CO', 'Explora Preguntas'),
    new SoleText('ui.home.lets_get_started', 'es_CO', 'Empecemos'),
    new SoleText('ui.home.welcome_card_text', 'es_CO', 'Podemos personalizar un breve mensaje aquí para diferentes círculos o para lo que sea'),
    new SoleText('ui.home.start_tour', 'es_CO', 'Empezar recorrido'),
    new SoleText('ui.admin.educators', 'es_CO', 'Educadores'),
    new SoleText('ui.admin.map', 'es_CO', 'Mapa'),
    new SoleText('ui.admin.soles', 'es_CO', 'SOLEs'),
    new SoleText('ui.admin.schools', 'es_CO', 'Colegios'),
    new SoleText('ui.admin.loading_schools', 'es_CO', 'Cargando colegios...'),
    new SoleText('ui.admin.loading_educators', 'es_CO', 'Cargando educadores...'),
    new SoleText('ui.admin.loading_email settings', 'es_CO', 'Cargando configuración de correo...'),
    new SoleText('ui.admin.loading_question', 'es_CO', 'Cargando Pregunta...'),
    new SoleText('ui.admin.soles_by_grade', 'es_CO', 'SOLEs por Grado'),
    new SoleText('ui.admin.soles_by_subject', 'es_CO', 'SOLES por Asignatura'),
    new SoleText('ui.admin.users_by_subject', 'es_CO', 'Educadores por Asignatura'),
    new SoleText('ui.admin.users_by_grade', 'es_CO', 'Educadores por Grado'),
    new SoleText('ui.admin.users_per_month_title', 'es_CO', 'Nuevos registros de usuario'),
    new SoleText('ui.admin.users_per_month_x_axis', 'es_CO', 'Mes'),
    new SoleText('ui.admin.users_per_month_y_axis', 'es_CO', 'Usuarios nuevos'),
    new SoleText('ui.admin.loading_soles', 'es_CO', 'Cargando SOLEs...'),
    new SoleText('ui.learn_more', 'es_CO', 'Aprende más'),
    new SoleText('ui.plan.big_question_and_standard', 'es_CO', 'Gran Pregunta'),
    new SoleText('ui.plan.what_is_your_big_question', 'es_CO', '¿Cuál es tu Gran Pregunta?'),
    new SoleText('ui.plan.setup_public_token', 'es_CO', '¿Crear un enlace público?'),
    new SoleText('ui.profile.about_me', 'es_CO', 'Sobre mí'),
    new SoleText('ui.profile.location', 'es_CO', 'Ubicación'),
    new SoleText('ui.profile.email', 'es_CO', 'Correo electrónico'),
    new SoleText('ui.profile.certificates', 'es_CO', 'Certificados'),
    new SoleText('ui.profile.password', 'es_CO', 'Contraseña'),
    new SoleText('ui.profile.trainer_materials', 'es_CO', 'Formador/Explorador'),
    new SoleText('ui.profile.certificate_explanation', 'es_CO', 'Los educadores ganan puntos por su trabajo con StartSOLE. Puedes descargar tus certificados aquí.'),
    new SoleText('ui.profile.no_certificates', 'es_CO', 'No has obtenido ningún certificado ... todavía.'),
    new SoleText('ui.profile.progress_caption', 'es_CO', '/ 2000 puntos para tu próximo certificado'),
    new SoleText('ui.profile.trainer_explanation', 'es_CO', 'Por favor recuerda compartir cualquier contenido copiado con apoyo@startsole.org para que podamos aprender de tu experiencia.'),
    new SoleText('ui.profile.about_first_name', 'es_CO', 'Nombre'),
    new SoleText('ui.profile.about_last_name', 'es_CO', 'Apellido'),
    new SoleText('ui.profile.about_role', 'es_CO', 'Rol'),
    new SoleText('ui.profile.about_grades', 'es_CO', 'Grados'),
    new SoleText('ui.profile.about_subjects', 'es_CO', 'Asignaturas'),
    new SoleText('ui.profile.location_add', 'es_CO', 'Añade tu ubicación'),
    new SoleText('ui.profile.location_update', 'es_CO', 'Cambia tu ubicación'),
    new SoleText('ui.profile.location_explanation', 'es_CO', 'Utilizamos tu ubicación para personalizar tu experiencia y entregar contenido en tu idioma, si está disponible.'),
    new SoleText('ui.profile.complete_header', 'es_CO', 'Completa tu perfil'),
    new SoleText('ui.profile.need_more_info', 'es_CO', 'Necesitamos un poco más de información para personalizar tu experiencia, por favor completa los campos siguientes.'),
    new SoleText('ui.profile.new_password', 'es_CO', 'Nueva contraseña'),
    new SoleText('ui.profile.update_password', 'es_CO', 'Actualiza contraseña'),
    new SoleText('ui.profile.current_password', 'es_CO', 'Contraseña actual'),
    new SoleText('ui.profile.password_explanation', 'es_CO', 'Utiliza el siguiente formulario para restablecer la contraseña. Si estás teniendo dificultades y necesitas apoyo puedes contactarnos:'),
    new SoleText('ui.profile.password_updated', 'es_CO', 'Contraseña actualizada correctamente'),
    new SoleText('ui.profile.privacy_header', 'es_CO', 'Política de privacidad de StartSOLE'),
    new SoleText('ui.profile.privacy_body', 'es_CO', 'Tu privacidad es importante para nosotros. Utilizamos la información en esta página para personalizar el contenido para tu experiencia SOLE y entregar Grandes Preguntas alineadas con tus estándares, si están disponibles. Utilizamos cookies para almacenar en caché los datos para personalizar el contenido. No compartimos tu información con anunciantes. Si deseas leer nuestra política de privacidad completa puedes hacer clic en el enlace de abajo.'),
    new SoleText('ui.profile.privacy_button', 'es_CO', 'Política de privacidad'),
    new SoleText('ui.questions.all_subjects', 'es_CO', 'Todas las asignaturas'),
    new SoleText('ui.questions.mine', 'es_CO', 'MIAS'),
    new SoleText('ui.questions.saved', 'es_CO', 'GUARDADOS'),
    new SoleText('ui.questions.nothing_here', 'es_CO', 'Nada aquí... todavía.  Comparta sus propias preguntas con la comunidad SOLE.'),
    new SoleText('ui.profile.email_explanation', 'es_CO', 'Utiliza las casillas de verificación para gestionar cuándo recibir correos electrónicos de nosotros con base a tus acciones con StartSOLE.'),
    new SoleText('ui.plan.class_details', 'es_CO', 'Detalles del SOLE'),
    new SoleText('ui.plan.what_is_the_planned_date?', 'es_CO', '¿Cuál es la fecha prevista?'),
    new SoleText('ui.plan.what_is_the_planned_time?', 'es_CO', '¿Cuál es la duración prevista?'),
    new SoleText('ui.plan.objective_logistics', 'es_CO', 'Objetivos y Logística'),
    new SoleText('ui.plan.missing_question', 'es_CO', 'Por favor añade una pregunta antes de guardar'),
    new SoleText('ui.plan.how_will_you_manage', 'es_CO', '¿Cómo gestionarás este SOLE?'),
    new SoleText('ui.plan.missing_details', 'es_CO', 'Por favor completa los detalles de tu SOLE antes de guardar'),
    new SoleText('ui.plan.missing_logistics', 'es_CO', 'Por favor completa los detalles de tu SOLE antes de guardar'),
    new SoleText('ui.plan.missing_logistics_objective', 'es_CO', 'Por favor, selecciona un objetivo para tu SOLE'),
    new SoleText('ui.reflect.missing_photos', 'es_CO', 'Por favor, sube una o varias fotos para documentar este SOLE'),
    new SoleText('ui.public.title', 'es_CO', '_titulo_marcador de posición_'),
    new SoleText('ui.public.copy_link', 'es_CO', 'Copia el siguiente enlace para enviar a los participantes:'),
    new SoleText('ui.question.plan_a_lesson', 'es_CO', 'Planea un SOLE'),
    new SoleText('ui.question.virtual_sole', 'es_CO', 'SOLE Virtual'),
    new SoleText('ui.question.added_to_favorites', 'es_CO', 'Agregado a Favoritos'),
    new SoleText('ui.question.removed_from_favorites', 'es_CO', 'Eliminado de Favoritos'),
    new SoleText('ui.question.resources', 'es_CO', 'Recursos relacionados'),
    new SoleText('ui.reflect.add_photos', 'es_CO', 'Agrega fotos'),
    new SoleText('ui.reflect.mobile.drop_photos', 'es_CO', 'Haz clic aquí para agregar fotos'),
    new SoleText('ui.reflect.drop_photos', 'es_CO', 'Arrastra y suelta fotos aquí o haz clic para navegar'),
    new SoleText('ui.reflect.review_photos', 'es_CO', 'Revisa las fotos'),
    new SoleText('ui.reflect.objective_question', 'es_CO', '¿Cuál era tu objetivo para este SOLE?'),
    new SoleText('ui.reflect.objective_question_suffix', 'es_CO', ' fue tu objetivo para este SOLE.'),
    new SoleText('ui.reflect.objective_question_achieved', 'es_CO', '¿Crees que lograste este objetivo?'),
    new SoleText('ui.reflect_objectives', 'es_CO', 'Objetivos'),
    new SoleText('ui.reflect_ratings', 'es_CO', 'Observaciones'),
    new SoleText('ui.reflect_final', 'es_CO', 'Finalmente'),
    new SoleText('ui.reflect_why_do_you_think_this', 'es_CO', '¿Por qué piensas esto?'),
    new SoleText('ui.reflect_actively_engaged', 'es_CO', '¿Qué porcentaje de estudiantes están comprometidos activamente?'),
    new SoleText('ui.reflect_shared_responsibility', 'es_CO', '¿Qué porcentaje de los estudiantes trabajaron juntos efectiva y respetuosamente y compartieron la responsabilidad?'),
    new SoleText('ui.reflect_technology_effectively', 'es_CO', '¿Qué porcentaje de grupos usaron la tecnología de forma efectiva?'),
    new SoleText('ui.reflect_articulate_listen', 'es_CO', '¿Qué porcentaje de estudiantes articularon los pensamientos y escucharon de forma efectiva?'),
    new SoleText('reflection.ground_rules', 'es_CO', '¿Los estudiantes notaron y siguieron las reglas establecidas?'),
    new SoleText('reflection.need_help', 'es_CO', '¿Necesitas asistencia adicional?'),
    new SoleText('ui.reflect.missing_ground_rules', 'es_CO', 'Por favor, haznos saber si los participantes siguieron las reglas establecidas'),
    new SoleText('reflection.help_text', 'es_CO', 'Podría necesitar ayuda con...'),
    new SoleText('ui.reflect_request_help', 'es_CO', 'Solicitar ayuda'),
    new SoleText('ui.resources.shared_by', 'es_CO', 'Compartido por'),
    new SoleText('ui.soles.loading_soles', 'es_CO', 'Cargando SOLEs...'),
    new SoleText('ui.soles.saving_sole', 'es_CO', 'Guardando SOLE...'),
    new SoleText('ui.soles.copying_sole', 'es_CO', 'Copiando SOLE...'),
    new SoleText('ui.soles.planned', 'es_CO', 'Planeado'),
    new SoleText('ui.soles.reflect', 'es_CO', 'Reflexiona'),
    new SoleText('ui.soles.completed', 'es_CO', 'Completado'),
    new SoleText('ui.sole-card.virtual', 'es_CO', 'Virtual'),
    new SoleText('ui.sole-card.delete_title', 'es_CO', '¿Seguro que quieres eliminar este SOLE?'),
    new SoleText('ui.sole-card.delete_body', 'es_CO', 'La eliminación de este SOLE eliminará todos los datos asociados y quitará este SOLE de tu lista. ¿Estas seguro que deseas continuar?'),
    new SoleText('ui.sole-card.download_plan', 'es_CO', 'Descargar Plan'),
    new SoleText('ui.sole-card.download_summary', 'es_CO', 'Descargar el Resúmen'),
    new SoleText('ui.sole-card.reflect', 'es_CO', 'Reflexiona'),
    new SoleText('ui.sole-card.edit', 'es_CO', 'Edita el Plan'),
    new SoleText('ui.search.title', 'es_CO', 'Bienvenido a Busqueda de Participantes'),
    new SoleText('ui.search.explanation_details', 'es_CO', 'StartSOLE se ha asociado con proveedores de contenido de terceros confiables. StartSOLE Busqued de Participantes le permite crear rápidamente un entorno de búsqueda de contenido personalizado para sus participantes que devuelve contenido confiable, relevante y apropiado al nivel del grado.'),
    new SoleText('ui.search.need_a_question', 'es_CO', 'Para comenzar, necesitarás una gran pregunta. Usa una de las opciones a continuación para buscar o crear una pregunta, luego haz clic en el botón azul \'Búsqueda de Participantes\' para configurar su enlace para participantes.'),
new SoleText('ui.virtual.title', 'es_CO', 'Bienvenido a StartSOLE Virtual'),
    new SoleText('ui.virtual.ready_to_go', 'es_CO', '¡Estás listo para iniciar el SOLE Virtual!'),
    new SoleText('ui.virtual.button_text', 'es_CO', 'Estoy interesado/a'),
    new SoleText('ui.virtual.button_setup', 'es_CO', 'Configuración de SOLE Virtual'),
    new SoleText('ui.virtual.button_instructions', 'es_CO', 'Haz clic en el botón de abajo para crear la sesión de SOLE Virtual.'),
    new SoleText('ui.virtual.click_the_button_explanation', 'es_CO', 'Haz clic en el botón de abajo para iniciar tu SOLE Virtual'),
    new SoleText('ui.virtual.copy_link', 'es_CO', 'Copia el enlace de abajo para enviarlo a los Participantes:'),
    new SoleText('ui.virtual.explanation', 'es_CO', 'StartSOLE Virtual es una herramienta que estamos probando para permitir a los educadores facilitar una sesión SOLE de forma remota. Parece que aún no está disponible en tu región, pero si estás interesado en obtener más información, házlo saber mediante el formulario a continuación:'),
    new SoleText('ui.virtual.get_started', 'es_CO', 'Inicia'),
    new SoleText('ui.virtual.share_to_classroom', 'es_CO', 'Comparte con Google Classroom'),
    new SoleText('ui.virtual.explanation_details', 'es_CO', 'StartSOLE Virtual te permite facilitar una sesión SOLE de forma remota con características útiles como el chat, compartir webcam, pizarras de varios usuarios, compartir pantalla, salas externas para trabajar en grupos pequeños.'),
    new SoleText('ui.virtual.need_a_question', 'es_CO', 'Para empezar necesitarás un Gran Pregunta. Utiliza una de las siguientes opciones para encontrar o proponer una Pregunta, luego, haz clic en el botón azul de \'StartSOLE Virtual\' para crear tu sesión.'),
    new SoleText('ui.virtual.constraint.guestPolicy', 'es_CO', 'Moderadores deber aprobar a los Participantes'),
    new SoleText('ui.virtual.constraint.lockSettingsDisablePrivateChat', 'es_CO', 'Desactivar el chat privado para los Participantes'),
    new SoleText('ui.virtual.constraint.lockSettingsDisableNote', 'es_CO', 'Deshabilitar notas compartidas para los Participantes'),
    new SoleText('ui.plan.class_label', 'es_CO', 'Etiqueta del curso (opcional)'),
    new SoleText('error.min.default', 'es_CO', 'Este valor es muy bajo'),
    new SoleText('error.min.session.planned_duration', 'es_CO', 'SOLEs deberían ser de por lo menos 30 minutos '),
    new SoleText('error.min.session.num_devices', 'es_CO', 'Un SOLE requiere al menos 1 dispositivo'),
    new SoleText('error.min.session.num_students', 'es_CO', 'Un SOLE requiere al menos 3 participantes'),
    new SoleText('error.min.session.num_groups', 'es_CO', 'Un SOLE requiere al menos 2 grupos'),
    new SoleText('error.fetching_questions', 'es_CO', 'Algo salió mal al buscar tus preguntas'),
    new SoleText('ui.join', 'es_CO', 'Unirte'),
    new SoleText('ui.enter_code', 'es_CO', 'Ingresa tu código para unirte a una sesión SOLE'),
    new SoleText('ui.login.logging_in', 'es_CO', 'Iniciando sesión...'),
    new SoleText('ui.login.sign_in', 'es_CO', 'Iniciar sesión'),
    new SoleText('ui.login.google_unavailable', 'es_CO', 'El inicio de sesión de Google no está disponible'),
    new SoleText('ui.login.or', 'es_CO', 'o'),
    new SoleText('ui.login.forgot_password', 'es_CO', '¿Olvidaste tu contraseña?'),
    new SoleText('ui.login.no_account', 'es_CO', '¿No tienes una cuenta? Inscríbete'),
    new SoleText('ui.login.reset_password', 'es_CO', 'Restablecer la contraseña'),
    new SoleText('ui.login.reset_password_confirmation', 'es_CO', 'Ingrese su correo electrónico a continuación y le enviaremos un enlace para restablecer su contraseña.'),
    new SoleText('ui.login.reset_password_cancel', 'es_CO', 'Enviar un correo electrónico de reinicio'),
    new SoleText('ui.login.reset_password_success', 'es_CO', '¡Restablecimiento de contraseña!'),
    new SoleText('ui.register', 'es_CO', 'Registrarse'),
    new SoleText('ui.register.sign_up', 'es_CO', 'Inscribirse'),
    new SoleText('ui.register.register_with_google', 'es_CO', 'Regístrese con su cuenta de Google'),
    new SoleText('ui.register.already_have_account', 'es_CO', '¿Ya tienes una cuenta? Acceso'),
    new SoleText('ui.maintenance.title', 'es_CO', 'Próximo mantenimiento'),
    new SoleText('ui.maintenance.description', 'es_CO', 'Realizaremos un mantenimiento programado en nuestro sitio web y aplicaciones móviles. Nos disculpamos por cualquier inconveniente. Nuestras herramientas no estarán disponibles de'),
    new SoleText('all', 'es_CO', 'Todos los grados', '', ''),
    new SoleText('edu.k', 'es_CO', 'Preescolar', '', ''),
    new SoleText('edu.1', 'es_CO', 'Grado 1', '', ''),
    new SoleText('edu.2', 'es_CO', 'Grado 2', '', ''),
    new SoleText('edu.3', 'es_CO', 'Grado 3', '', ''),
    new SoleText('edu.4', 'es_CO', 'Grado 4', '', ''),
    new SoleText('edu.5', 'es_CO', 'Grado 5', '', ''),
    new SoleText('edu.6', 'es_CO', 'Grado 6', '', ''),
    new SoleText('edu.7', 'es_CO', 'Grado 7', '', ''),
    new SoleText('edu.8', 'es_CO', 'Grado 8', '', ''),
    new SoleText('edu.9', 'es_CO', 'Grado 9', '', ''),
    new SoleText('edu.10', 'es_CO', 'Grado 10', '', ''),
    new SoleText('edu.11', 'es_CO', 'Grado 11', '', ''),
    new SoleText('edu.12', 'es_CO', 'Grado 12', '', ''),
    new SoleText('edu.adulteducation', 'es_CO', 'Educación de Adultos', '', ''),
    new SoleText('top.careereducation', 'es_CO', 'Educación profesional', '', ''),
    new SoleText('top.engineering', 'es_CO', 'Ingeniería', '', ''),
    new SoleText('top.english', 'es_CO', 'Inglés', '', ''),
    new SoleText('top.englishlanguagedevelopment', 'es_CO', 'Desarrollo del idioma inglés', '', ''),
    new SoleText('top.financialliteracy', 'es_CO', 'Alfabetización Financiera', '', ''),
    new SoleText('top.foreignlanguage', 'es_CO', 'Lenguas extranjeras', '', ''),
    new SoleText('top.health', 'es_CO', 'Salud', '', ''),
    new SoleText('top.libmedia-infolit', 'es_CO', 'Información bibliográfica/medios', '', ''),
    new SoleText('top.lifeskills', 'es_CO', 'Habilidades para la vida', '', ''),
    new SoleText('top.math', 'es_CO', 'Matemáticas', '', ''),
    new SoleText('top.physicaleducation', 'es_CO', 'Educación Física', '', ''),
    new SoleText('top.science', 'es_CO', 'Ciencias', '', ''),
    new SoleText('top.socialstudies', 'es_CO', 'Ciencias Sociales', '', ''),
    new SoleText('top.technology', 'es_CO', 'Tecnología', '', ''),
    new SoleText('top.thearts', 'es_CO', 'Artes', '', ''),
    new SoleText('edu.pre-k', 'es_CO', 'Preescolar', '', ''),
    new SoleText('session.groupsharing.behaviour', 'es_CO', 'Preocupaciones de comportamiento', '', ''),
    new SoleText('session.groupsharing.time', 'es_CO', 'Preocupacions de manejo del tiempo', '', ''),
    new SoleText('session.groupsharing.originality', 'es_CO', 'Preocupaciones de originalidad', '', ''),
    new SoleText('session.groupsharing.space', 'es_CO', 'Limitaciones de espacio físico', '', ''),
    new SoleText('session.groupsharing.other', 'es_CO', 'Otros', '', ''),
    new SoleText('objective.content.standard', 'es_CO', 'Introducción a los participantes a un nuevo concepto', '', ''),
    new SoleText('objective.content.prior', 'es_CO', 'Ayudar a los participantes a conectar con conocimientos previos de un tema actual', '', ''),
    new SoleText('objective.content.explore', 'es_CO', 'Permitir a participantes descubrir y explorar intereses relacionados al tema', '', ''),
    new SoleText('objective.content.deepen', 'es_CO', 'Ayudar a los participantes a profundizar el entendimiento de un tema actual', '', ''),
    new SoleText('objective.content.review', 'es_CO', 'Revisar los conocimientos de una tema actual', '', ''),
    new SoleText('objective.content.assess', 'es_CO', 'Evaluar la comprensión de los participantes sobre un tema actual.', '', ''),
    new SoleText('objective.process.collaboration_skills', 'es_CO', 'Quiero que mis participantes mejoren sus habilidades de colaboración.', '', ''),
    new SoleText('objective.process.communication_skills', 'es_CO', 'Quiero que mis participantes mejoren sus habilidades de comunicación.', '', ''),
    new SoleText('objective.process.deeper_learning', 'es_CO', 'Quiero un aprendizaje más profundo en mi espacio de aprendizaje (taxonomía de Bloom)', '', ''),
    new SoleText('objective.process.engagement', 'es_CO', 'Quiero aumentar la participación y compromiso de los participantes', '', ''),
    new SoleText('material.writing_tools', 'es_CO', 'Herramientas de escritura', 'Herramientas de escritura', ''),
    new SoleText('material.poster_paper', 'es_CO', 'Papel de cartelera', 'Papel de cartelera', ''),
    new SoleText('material.physical', 'es_CO', 'Recursos físicos (libros, contenidos de apoyo)', 'Recursos físicos (libros, contenidos de apoyo)', ''),
    new SoleText('material.sole_organizer', 'es_CO', 'Organizador de Paricipantes SOLE', 'Organizador de Participantes SOLE', ''),
    new SoleText('material.other', 'es_CO', 'Otros materiales', 'Otros materiales', ''),
    new SoleText('reflect.agree.strongly_agree', 'es_CO', 'Muy de acuerdo', '', ''),
    new SoleText('reflect.agree.agree', 'es_CO', 'De acuerdo', '', ''),
    new SoleText('reflect.agree.disagree', 'es_CO', 'En desacuerdo', '', ''),
    new SoleText('reflect.agree.strongly_disagree', 'es_CO', 'Muy en desacuerdo', '', ''),
    new SoleText('session.observation.collaborating', 'es_CO', 'Colaborar', '', ''),
    new SoleText('session.observation.technology', 'es_CO', 'Usar la tecnología bien', '', ''),
    new SoleText('session.observation.respectful', 'es_CO', 'Diálogo y debate respetuoso', '', ''),
    new SoleText('session.observation.desire', 'es_CO', 'Deseo de aprender contenido', '', ''),
    new SoleText('session.observation.vocabulary', 'es_CO', 'Uso apropiado de vocabulario', '', ''),
    new SoleText('session.observation.help_learn', 'es_CO', 'Participantes enseñando a participantes', 'Efecto de la Sesión', ''),
    new SoleText('session.observation.help_technology', 'es_CO', 'Ayudando a compañer@s con la tecnología', '', ''),
    new SoleText('session.observation.keyboard', 'es_CO', 'Dificultad para usar el teclado', '', ''),
    new SoleText('session.observation.writing', 'es_CO', 'Dificultad con la escritura', '', ''),
    new SoleText('session.observation.reading', 'es_CO', 'Dificultad con la lectura', '', ''),
    new SoleText('session.observation.turns', 'es_CO', 'No toma turnos', '', ''),
    new SoleText('session.observation.imbalanced', 'es_CO', 'Grupos desbalanceados', '', ''),
    new SoleText('session.observation.plagiarizing', 'es_CO', 'Plagiando información', '', ''),
    new SoleText('session.observation.one_source', 'es_CO', 'Usando una sola fuente', '', ''),
    new SoleText('session.observation.complaining', 'es_CO', 'Quejándose', '', ''),
    new SoleText('session.observation.off_task', 'es_CO', 'Distraidos', '', ''),
    new SoleText('session.observation.inaccurate', 'es_CO', 'Información inexacta o irrelevante', '', ''),
    new SoleText('session.observation.ohmeansjobs_collaborating', 'es_CO', 'Trabajo en equipo / Colaboración', '', ''),
    new SoleText('session.observation.ohmeansjobs_critical_thinking', 'es_CO', 'Pensamiento Crítico / Resolución de problemas', '', ''),
    new SoleText('session.observation.ohmeansjobs_leadership', 'es_CO', 'Liderazgo', '', ''),
    new SoleText('session.observation.ohmeansjobs_creativity', 'es_CO', 'Creatividad / Innovación', '', ''),
    new SoleText('session.observation.ohmeansjobs_communication', 'es_CO', 'Comunicación Oral/Escrita', '', ''),
    new SoleText('session.observation.ohmeansjobs_intercultural_fluency', 'es_CO', 'Fluidez Global/Intercultural', '', ''),
    new SoleText('session.observation.engagement', 'es_CO', 'Compromiso', '', ''),
    new SoleText('session.observation.other', 'es_CO', 'Otra observación', '', ''),
    new SoleText('session.observation.group.presentation', 'es_CO', 'Presentación de Grupo', 'Presentación de Grupo', ''),
    new SoleText('reflection.type_of_thinking.recall', 'es_CO', 'Recordar y reproducir', '', ''),
    new SoleText('reflection.type_of_thinking.skill', 'es_CO', 'Habilidad / Concepto', '', ''),
    new SoleText('reflection.type_of_thinking.short_strategic', 'es_CO', 'Estratégico a corto plazo', '', ''),
    new SoleText('reflection.type_of_thinking.long_strategic', 'es_CO', 'Estratégico a largo plazo', '', ''),
    new SoleText('reflectionReminders', 'es_CO', 'Recordatorios de reflexión', '', ''),
    new SoleText('planningDoc', 'es_CO', 'Documentos de planificación', '', ''),
    new SoleText('summaryDoc', 'es_CO', 'Documentos de resumen', '', ''),
    new SoleText('ceuDoc', 'es_CO', 'Documentos CEU', '', ''),
    new SoleText('questionTips', 'es_CO', 'Consejos para preguntas', '', ''),
    new SoleText('virtualEnded', 'es_CO', 'SOLE virtual terminada', '', ''),
    new SoleText('virtualRecordings', 'es_CO', 'Grabaciones de SOLE virtual', '', ''),
    new SoleText('user.role.teacher', 'es_CO', 'Maestro', '', ''),
    new SoleText('user.role.preservice_teacher', 'es_CO', 'Maestro en formación', '', ''),
    new SoleText('user.role.administrator', 'es_CO', 'Administrador', '', ''),
    new SoleText('user.role.out_of_school_educator', 'es_CO', 'Educador fuera de la escuela', '', ''),
    new SoleText('user.role.coach', 'es_CO', 'Entrenador', '', ''),
    new SoleText('user.role.parent', 'es_CO', 'Padre', '', ''),
    new SoleText('user.role.other', 'es_CO', 'Otro', '', ''),
    new SoleText('session.target_observations_set.default', 'es_CO', 'Observaciones por defecto', '', ''),
    new SoleText('session.target_observations_set.ohiomeansjobs', 'es_CO', 'Observaciones # 1', '', ''),
    new SoleText('session.target_observations_set.xyz', 'es_CO', 'Observaciones # 2', '', ''),
    new SoleText('session.planned_duration', 'es_CO', 'Duración', '¿Cuál es la duración del la sesión?', ''),
    new SoleText('session.num_devices', 'es_CO', 'Dispositivos', '¿Cuántos dispositivos están disponibles?', '¿Cuántos dispositivos habilitados para Internet hay disponibles?'),
    new SoleText('session.num_students', 'es_CO', 'Participantes', '¿Cuántos participantes?', '¿Cuántos participantes hay en este SOLE?'),
    new SoleText('session.num_groups', 'es_CO', 'Grupos', '¿Cuántos grupos para este SOLE?', 'Número de grupos'),
    new SoleText('session.grade', 'es_CO', 'Calificación', '¿Para qué grado es este SOLE?', ''),
    new SoleText('session.subject', 'es_CO', 'Tema', '¿Cuál es la asignatura del SOLE?', ''),
    new SoleText('session.content_objective', 'es_CO', 'Objetivo de contenido', '¿Cuál es tu objetivo para este SOLE?', '¿Cuál es tu objetivo para este SOLE?'),
    new SoleText('session.materials', 'es_CO', 'Materiales', '¿Qué materiales estarás usando durante este SOLE?', ''),
    new SoleText('session.grouporganization', 'es_CO', '', '¿Los participantes formarán sus propios grupos?', ''),
    new SoleText('session.groupsharing', 'es_CO', '', '¿Permitirás que los grupos compartan entre sí mientras investigan?', ''),
    new SoleText('session.self_assessment', 'es_CO', '', '¿Los participantes completarán un boleto de salida después del SOLE?', ''),
    new SoleText('session.target_observations', 'es_CO', 'Observaciones', 'Escoge 3 comportamientos de los participantes en los que te gustaría enfocarte durante este SOLE', ''),
    new SoleText('session.reflection.type_of_thinking', 'es_CO', 'Tipo de Pensamiento', '¿Qué tipo de pensamiento usaron más los participantes durante este SOLE?', ''),
    new SoleText('ui.logout', 'es_CO', 'Cerrar sesión'),
    new SoleText('ui.plan.standard', 'es_CO', 'Por favor, selecciona una de las siguientes opciones:'),

    new SoleText('ui.save', 'ja_JP', '保存'),
    new SoleText('ui.delete', 'ja_JP', '削除'),
    new SoleText('ui.approve', 'ja_JP', '承認'),
    new SoleText('ui.reject', 'ja_JP', '辞退'),
    new SoleText('ui.cancel', 'ja_JP', 'キャンセル'),
    new SoleText('ui.search_by_keyword', 'ja_JP', '検索キーワード'),
    new SoleText('ui.search_questions', 'ja_JP', '検索'),
    new SoleText('ui.ok', 'ja_JP', 'OK'),
    new SoleText('ui.download', 'ja_JP', 'ダウンロード'),
    new SoleText('ui.undo', 'ja_JP', '元に戻す'),
    new SoleText('ui.favorite', 'ja_JP', 'お気に入り'),
    new SoleText('ui.unfavorite', 'ja_JP', 'お気に入りから削除'),
    new SoleText('ui.contact_us', 'ja_JP', 'お問い合わせ'),
    new SoleText('ui.support', 'ja_JP', 'support@startsole.org'),
    new SoleText('ui.add_a_question', 'ja_JP', 'Big Question 作成'),
    new SoleText('ui.adding_question', 'ja_JP', '問いを追加しています・・・'),
    new SoleText('ui.enter_your_question', 'ja_JP', 'Big Question を入力'),
    new SoleText('ui.submit_question', 'ja_JP', '送信'),
    new SoleText('ui.back', 'ja_JP', '戻る'),
    new SoleText('ui.close', 'ja_JP', '閉じる'),
    new SoleText('ui.continue', 'ja_JP', '次へ'),
    new SoleText('ui.copy', 'ja_JP', 'コピー'),
    new SoleText('ui.copied_to_clipboard', 'ja_JP', 'クリップボードにコピーしました'),
    new SoleText('ui.how_to_sole', 'ja_JP', 'SOLE の実施方法'),
    new SoleText('ui.history_of_startsole', 'ja_JP', 'StartSOLE の歴史'),
    new SoleText('ui.loading', 'ja_JP', '読み込み中・・・'),
    new SoleText('ui.my_profile', 'ja_JP', 'プロフィール'),
    new SoleText('ui.question', 'ja_JP', '問い'),
    new SoleText('ui.admin', 'ja_JP', '管理者'),
    new SoleText('ui.my_questions', 'ja_JP', '私の Big Question'),
    new SoleText('ui.my_soles', 'ja_JP', '私の SOLE'),
    new SoleText('ui.my_community', 'ja_JP', '私のコミュニティ'),
    new SoleText('ui.plan_a_lesson', 'ja_JP', 'レッスンプランを作成'),
    new SoleText('ui.teacher_resources', 'ja_JP', '先生用の資料'),
    new SoleText('ui.virtual', 'ja_JP', 'バーチャル'),
    new SoleText('ui.explore_questions', 'ja_JP', 'Big Question 検索'),
    new SoleText('ui.home.lets_get_started', 'ja_JP', 'スタート'),
    new SoleText('ui.home.welcome_card_text', 'ja_JP', ''),
    new SoleText('ui.home.start_tour', 'ja_JP', 'ツアーをスタート'),
    new SoleText('ui.admin.educators', 'ja_JP', '先生'),
    new SoleText('ui.admin.map', 'ja_JP', '地図'),
    new SoleText('ui.admin.soles', 'ja_JP', 'SOLE'),
    new SoleText('ui.admin.schools', 'ja_JP', '学校'),
    new SoleText('ui.admin.loading_schools', 'ja_JP', '学校の情報を読み込んでいます・・・'),
    new SoleText('ui.admin.loading_educators', 'ja_JP', '先生の情報を読み込んでいます・・・'),
    new SoleText('ui.admin.loading_email settings', 'ja_JP', 'メール設定を読み込んでいます・・・'),
    new SoleText('ui.admin.loading_question', 'ja_JP', '問いを読み込んでいます・・・'),
    new SoleText('ui.admin.soles_by_grade', 'ja_JP', 'SOLE 学年別'),
    new SoleText('ui.admin.soles_by_subject', 'ja_JP', 'SOLE 教科別'),
    new SoleText('ui.admin.users_by_subject', 'ja_JP', '先生 教科別'),
    new SoleText('ui.admin.users_by_grade', 'ja_JP', '先生 学年別'),
    new SoleText('ui.admin.users_per_month_title', 'ja_JP', '新規ユーザー登録'),
    new SoleText('ui.admin.users_per_month_x_axis', 'ja_JP', '月'),
    new SoleText('ui.admin.users_per_month_y_axis', 'ja_JP', '新しいユーザー'),
    new SoleText('ui.admin.loading_soles', 'ja_JP', 'SOLE の情報を読み込んでいます・・・'),
    new SoleText('ui.learn_more', 'ja_JP', '詳細を見る'),
    new SoleText('ui.plan.big_question_and_standard', 'ja_JP', 'Big Question'),
    new SoleText('ui.plan.what_is_your_big_question', 'ja_JP', 'Big Question は何ですか？'),
    new SoleText('ui.profile.about_me', 'ja_JP', '私について'),
    new SoleText('ui.profile.location', 'ja_JP', '所在地'),
    new SoleText('ui.profile.email', 'ja_JP', 'メール'),
    new SoleText('ui.profile.certificates', 'ja_JP', '証明書'),
    new SoleText('ui.profile.password', 'ja_JP', 'パスワード'),
    new SoleText('ui.profile.trainer_materials', 'ja_JP', 'トレーナー'),
    new SoleText('ui.profile.certificate_explanation', 'ja_JP', '先生はStartSOLEを活用することでポイントを獲得できます。一定のポイントを獲得すると、ポイント証明書をダウンロードすることができます。'),
    new SoleText('ui.profile.no_certificates', 'ja_JP', 'あなたはまだポイント証明書を獲得できていません。'),
    new SoleText('ui.profile.progress_caption', 'ja_JP', '/2000 ポイントで次のポイント証明書を獲得できます。'),
    new SoleText('ui.profile.trainer_explanation', 'ja_JP', '当サイト上にあるコンテンツをコピーして使用した場合は、あなたの経験から学ばせていただくためにも、support@startsole.org までご連絡をお願いします。'),
    new SoleText('ui.profile.about_first_name', 'ja_JP', 'ファーストネーム'),
    new SoleText('ui.profile.about_last_name', 'ja_JP', 'ラストネーム'),
    new SoleText('ui.profile.about_role', 'ja_JP', '役職'),
    new SoleText('ui.profile.about_grades', 'ja_JP', '担当学年 (複数選択可)'),
    new SoleText('ui.profile.about_subjects', 'ja_JP', '教科'),
    new SoleText('ui.profile.location_add', 'ja_JP', '所在地'),
    new SoleText('ui.profile.location_update', 'ja_JP', '所在地を変更'),
    new SoleText('ui.profile.location_explanation', 'ja_JP', '当サイトは、あなたの利便性を高める目的で、あなたの所在地の情報を使用して、当該地域の言語で提供できるコンテンツがあれば提供します。'),
    new SoleText('ui.profile.complete_header', 'ja_JP', 'プロフィールを完成'),
    new SoleText('ui.profile.need_more_info', 'ja_JP', 'あなたの利便性を高めるため、もう少し情報が必要です。下の空欄に入力してください。'),
    new SoleText('ui.profile.new_password', 'ja_JP', '新しいパスワード'),
    new SoleText('ui.profile.update_password', 'ja_JP', 'パスワードを更新'),
    new SoleText('ui.profile.current_password', 'ja_JP', '現在のパスワード'),
    new SoleText('ui.profile.password_explanation', 'ja_JP', '以下のフォームを使ってパスワードをリセットしてください。ご不明な点があれば、お問い合わせください。'),
    new SoleText('ui.profile.password_updated', 'ja_JP', 'パスワードが更新されました。'),
    new SoleText('ui.profile.privacy_header', 'ja_JP', 'StartSOLE プライバシーポリシー'),
    new SoleText('ui.profile.privacy_body', 'ja_JP', 'あなたのプライバシーは大切です。当サイトは、このページ上の情報を使って、あなたの SOLE にあわせてカスタマイズして提供できるコンテンツや、ねらいにあったBig Questionがあれば提供します。また、当サイトでは、クッキーを使用してキャッシュにあなたの個人設定を保存します。なお、あなたの情報を広告会社に渡すことはありません。プライバシーポリシーの全文をご覧になりたい場合は、以下のリンクをクリックしてください。'),
    new SoleText('ui.profile.privacy_button', 'ja_JP', 'プライバシーポリシー'),
    new SoleText('ui.questions.all_subjects', 'ja_JP', '全教科'),
    new SoleText('ui.questions.mine', 'ja_JP', '私のもの'),
    new SoleText('ui.questions.saved', 'ja_JP', '保存済み'),
    new SoleText('ui.profile.email_explanation', 'ja_JP', 'お知らせメールの受信設定'),
    new SoleText('ui.plan.class_details', 'ja_JP', 'クラスの詳細'),
    new SoleText('ui.plan.what_is_the_planned_date?', 'ja_JP', '予定日はいつですか？'),
    new SoleText('ui.plan.what_is_the_planned_time?', 'ja_JP', '時刻はいつですか？'),
    new SoleText('ui.plan.objective_logistics', 'ja_JP', '目標と計画'),
    new SoleText('ui.plan.missing_question', 'ja_JP', '保存する前に、問いを入力してください。'),
    new SoleText('ui.plan.missing_details', 'ja_JP', '保存する前に、クラスの詳細を入力してください。'),
    new SoleText('ui.plan.missing_logistics', 'ja_JP', '保存する前に、目標と計画を入力してください。'),
    new SoleText('ui.question.plan_a_lesson', 'ja_JP', 'レッスンプランを作成'),
    new SoleText('ui.question.virtual_sole', 'ja_JP', 'バーチャル SOLE'),
    new SoleText('ui.question.added_to_favorites', 'ja_JP', 'お気に入りに追加済み'),
    new SoleText('ui.question.removed_from_favorites', 'ja_JP', 'お気に入りから削除済み'),
    new SoleText('ui.question.resources', 'ja_JP', '関連資料'),
    new SoleText('ui.reflect.add_photos', 'ja_JP', '写真を追加'),
    new SoleText('ui.reflect.mobile.drop_photos', 'ja_JP', 'クリックして写真を追加'),
    new SoleText('ui.reflect.drop_photos', 'ja_JP', 'ドラッグ＆ドロップで写真を追加、またはファイルを検索'),
    new SoleText('ui.reflect.review_photos', 'ja_JP', '写真を見る'),
    new SoleText('ui.reflect.objective_question', 'ja_JP', '今回の SOLE のねらいは何ですか？'),
    new SoleText('ui.reflect.objective_question_suffix', 'ja_JP', ''),
    new SoleText('ui.reflect.objective_question_achieved', 'ja_JP', 'ねらいは達成できたと思いますか？'),
    new SoleText('ui.reflect_objectives', 'ja_JP', 'ねらい'),
    new SoleText('ui.reflect_ratings', 'ja_JP', '評価'),
    new SoleText('ui.reflect_request_help', 'ja_JP', 'ヘルプをリクエストする'),
    new SoleText('ui.resources.shared_by', 'ja_JP', 'シェアした人'),
    new SoleText('ui.soles.loading_soles', 'ja_JP', 'SOLE の情報を読み込んでいます・・・'),
    new SoleText('ui.soles.saving_sole', 'ja_JP', 'SOLE の情報を保存しています・・・'),
    new SoleText('ui.soles.copying_sole', 'ja_JP', 'SOLE の情報をコピーしています・・・'),
    new SoleText('ui.soles.planned', 'ja_JP', '計画済み'),
    new SoleText('ui.soles.reflect', 'ja_JP', '振り返り'),
    new SoleText('ui.soles.completed', 'ja_JP', '完了'),
    new SoleText('ui.sole-card.virtual', 'ja_JP', 'バーチャル'),
    new SoleText('ui.sole-card.delete_title', 'ja_JP', 'この SOLE の情報を本当に削除しますか？'),
    new SoleText('ui.sole-card.delete_body', 'ja_JP', 'この SOLE の情報を削除すると、関連する情報が完全に削除されます。本当に実行しますか？'),
    new SoleText('ui.sole-card.download_plan', 'ja_JP', 'レッスンプランをダウンロードする'),
    new SoleText('ui.sole-card.download_summary', 'ja_JP', 'サマリーをダウンロードする'),
    new SoleText('ui.sole-card.reflect', 'ja_JP', '振り返り'),
    new SoleText('ui.sole-card.edit', 'ja_JP', 'レッスンプランを編集'),
    new SoleText('ui.virtual.title', 'ja_JP', 'StartSOLE バーチャルへようこそ'),
    new SoleText('ui.virtual.ready_to_go', 'ja_JP', 'StartSOLE バーチャルを利用できるようになりました！'),
    new SoleText('ui.virtual.button_text', 'ja_JP', '興味があります'),
    new SoleText('ui.virtual.button_setup', 'ja_JP', 'バーチャル SOLE をセットアップする'),
    new SoleText('ui.virtual.button_instructions', 'ja_JP', '下のボタンをクリックして、バーチャル SOLE セッションを作成する'),
    new SoleText('ui.virtual.click_the_button_explanation', 'ja_JP', '下のボタンをクリックして、バーチャル SOLE セッションをスタートする'),
    new SoleText('ui.virtual.copy_link', 'ja_JP', '下のリンクをコピーして、参加者に送る'),
    new SoleText('ui.virtual.explanation', 'ja_JP', 'StartSOLE バーチャルは、先生方がリモートで SOLE セッションをファシリテートするためのツールです。あなたの地域ではご利用いただけないようです。詳細は以下のフォームからお問い合わせください。'),
    new SoleText('ui.virtual.get_started', 'ja_JP', 'スタートする'),
    new SoleText('ui.virtual.share_to_classroom', 'ja_JP', 'Google Classroom 上でシェアする'),
    new SoleText('ui.virtual.explanation_details', 'ja_JP', 'StartSOLE バーチャルは、リモートで SOLE セッションをファシリテートするツールで、チャット、ウェブカメラ共有、複数ユーザー同時編集可能ホワイトボード、画面共有、ブレイクアウトルームなどの機能を備えています。'),
    new SoleText('ui.virtual.need_a_question', 'ja_JP', 'スタートするには、Big Question が必要です。下のオプションから選択するか、新規作成し、青い「バーチャル SOLE」ボタンをクリックして、セッションをセットアップしてください。'),
    new SoleText('ui.virtual.constraint.guestPolicy', 'ja_JP', '参加するには、モデレータの承認が必要です。'),
    new SoleText('ui.virtual.constraint.lockSettingsDisablePrivateChat', 'ja_JP', '参加者同士のプライベートチャットを無効にする'),
    new SoleText('ui.virtual.constraint.lockSettingsDisableNote', 'ja_JP', '参加者同士のノート共有を無効にする'),
    new SoleText('ui.plan.class_label', 'ja_JP', 'クラス名（オプション）'),
    new SoleText('error.min.default', 'ja_JP', '値が低すぎます'),
    new SoleText('error.min.session.planned_duration', 'ja_JP', 'SOLE は30分以上で設定してください'),
    new SoleText('error.min.session.num_devices', 'ja_JP', 'SOLE には最低1台の端末が必要です'),
    new SoleText('error.min.session.num_students', 'ja_JP', 'SOLE には最低3名の生徒が必要です'),
    new SoleText('error.min.session.num_groups', 'ja_JP', 'SOLE には最低2グループ必要です'),
    new SoleText('error.fetching_questions', 'ja_JP', '問題が起こりました'),
    new SoleText('ui.login.logging_in', 'ja_JP', 'ログインしています・・・'),
    new SoleText('ui.login.sign_in', 'ja_JP', 'サインイン'),
    new SoleText('ui.login.google_unavailable', 'ja_JP', 'Google ログインが利用できません'),
    new SoleText('ui.login.or', 'ja_JP', 'または'),
    new SoleText('ui.login.forgot_password', 'ja_JP', 'パスワードを忘れたら？'),
    new SoleText('ui.login.no_account', 'ja_JP', 'アカウントを新規作成'),
    new SoleText('ui.login.reset_password', 'ja_JP', 'パスワードをリセット'),
    new SoleText('ui.login.reset_password_confirmation', 'ja_JP', '以下にメールアドレスを入力してください。パスワードをリセットするためのリンクをお送りします。'),
    new SoleText('ui.login.reset_password_cancel', 'ja_JP', 'リセットのメールを送信する'),
    new SoleText('ui.login.reset_password_success', 'ja_JP', 'パスワードがリセットされました'),
    new SoleText('ui.register', 'ja_JP', '新規登録'),
    new SoleText('ui.register.sign_up', 'ja_JP', 'サインアップ'),
    new SoleText('ui.register.register_with_google', 'ja_JP', 'Google アカウントを使って新規登録'),
    new SoleText('ui.register.already_have_account', 'ja_JP', 'アカウントをお持ちの方はこちらからログイン'),
    new SoleText('ui.maintenance.title', 'ja_JP', 'メンテナンスの予定'),
    new SoleText('ui.maintenance.description', 'ja_JP', 'ウェブサイトとアプリのメンテナンスが予定されています。ご不便をおかけして申し訳ございません。メンテナンス開始は'),
    new SoleText('edu.k', 'ja_JP', '未就学児', '', ''),
    new SoleText('edu.1', 'ja_JP', '小学1年', '', ''),
    new SoleText('edu.2', 'ja_JP', '小学2年', '', ''),
    new SoleText('edu.3', 'ja_JP', '小学3年', '', ''),
    new SoleText('edu.4', 'ja_JP', '小学4年', '', ''),
    new SoleText('edu.5', 'ja_JP', '小学5年', '', ''),
    new SoleText('edu.6', 'ja_JP', '小学6年', '', ''),
    new SoleText('edu.7', 'ja_JP', '中学1年', '', ''),
    new SoleText('edu.8', 'ja_JP', '中学2年', '', ''),
    new SoleText('edu.9', 'ja_JP', '中学3年', '', ''),
    new SoleText('edu.10', 'ja_JP', '高校1年', '', ''),
    new SoleText('edu.11', 'ja_JP', '高校2年', '', ''),
    new SoleText('edu.12', 'ja_JP', '高校3年', '', ''),
    new SoleText('edu.adulteducation', 'ja_JP', '成人教育', '', ''),
    new SoleText('top.careereducation', 'ja_JP', 'キャリア教育', '', ''),
    new SoleText('top.engineering', 'ja_JP', 'エンジニアリング', '', ''),
    new SoleText('top.english', 'ja_JP', 'English', '', ''),
    new SoleText('top.englishlanguagedevelopment', 'ja_JP', 'English Language Development', '', ''),
    new SoleText('top.financialliteracy', 'ja_JP', '金融リテラシー', '', ''),
    new SoleText('top.foreignlanguage', 'ja_JP', '外国語', '', ''),
    new SoleText('top.health', 'ja_JP', '保健（健康）', '', ''),
    new SoleText('top.libmedia-infolit', 'ja_JP', '図書館・メディア情報リテラシー', '', ''),
    new SoleText('top.lifeskills', 'ja_JP', 'ライフスキル', '', ''),
    new SoleText('top.math', 'ja_JP', '数学', '', ''),
    new SoleText('top.physicaleducation', 'ja_JP', '体育', '', ''),
    new SoleText('top.science', 'ja_JP', '理科', '', ''),
    new SoleText('top.socialstudies', 'ja_JP', '社会', '', ''),
    new SoleText('top.technology', 'ja_JP', 'テクノロジー', '', ''),
    new SoleText('top.thearts', 'ja_JP', '芸術', '', ''),
    new SoleText('edu.pre-k', 'ja_JP', 'プリスクール', '', ''),
    new SoleText('session.groupsharing.behaviour', 'ja_JP', '行動面の懸念', '', ''),
    new SoleText('session.groupsharing.time', 'ja_JP', '時間管理面の懸念', '', ''),
    new SoleText('session.groupsharing.originality', 'ja_JP', 'オリジナリティへの配慮', '', ''),
    new SoleText('session.groupsharing.space', 'ja_JP', '物理的空間の制限', '', ''),
    new SoleText('session.groupsharing.other', 'ja_JP', 'その他', '', ''),
    new SoleText('objective.content.standard', 'ja_JP', '生徒たちに新しい概念を導入する', '', ''),
    new SoleText('objective.content.prior', 'ja_JP', '生徒が事前の知識と現在のトピックを結びつけるのを助ける', '', ''),
    new SoleText('objective.content.explore', 'ja_JP', '生徒たちがトピックに関連する興味を発見し、探究することができるようにする', '', ''),
    new SoleText('objective.content.deepen', 'ja_JP', '生徒たちがトピックに対する理解を深めることができるよう手助けする', '', ''),
    new SoleText('objective.content.review', 'ja_JP', '現在の単元の知識を振り返る', '', ''),
    new SoleText('objective.content.assess', 'ja_JP', '最新のトピックの理解度を測る', '', ''),
    new SoleText('objective.process.collaboration_skills', 'ja_JP', '生徒のコラボレーションスキルを向上させたい', '', ''),
    new SoleText('objective.process.communication_skills', 'ja_JP', '生徒にはコミュニケーション能力を向上させてほしい', '', ''),
    new SoleText('objective.process.deeper_learning', 'ja_JP', '私は教室でより深い学びを求めている（ブルームス・タクソノミー）', '', ''),
    new SoleText('objective.process.engagement', 'ja_JP', '生徒のエンゲージメントを高めたい', '', ''),
    new SoleText('material.writing_tools', 'ja_JP', 'ライティングツール', 'ライティングツール', ''),
    new SoleText('material.poster_paper', 'ja_JP', 'ポスター用紙', 'ポスター用紙', ''),
    new SoleText('material.physical', 'ja_JP', '物理的リソース', '物理的リソース（本、資料）', '物理的リソース（本、資料）'),
    new SoleText('material.sole_organizer', 'ja_JP', 'SOLE Student Organizer', 'SOLE Student Organizer', ''),
    new SoleText('material.other', 'ja_JP', 'その他のマテリアル', 'その他のマテリアル', ''),
    new SoleText('reflect.agree.strongly_agree', 'ja_JP', '強く同意する', '', ''),
    new SoleText('reflect.agree.agree', 'ja_JP', '同意する', '', ''),
    new SoleText('reflect.agree.disagree', 'ja_JP', 'そう思わない', '', ''),
    new SoleText('reflect.agree.strongly_disagree', 'ja_JP', '強くそう思わない', '', ''),
    new SoleText('session.observation.collaborating', 'ja_JP', 'コラボレーション', '', ''),
    new SoleText('session.observation.technology', 'ja_JP', 'テクノロジーをうまく使う', '', ''),
    new SoleText('session.observation.respectful', 'ja_JP', '尊重された対話と議論', '', ''),
    new SoleText('session.observation.desire', 'ja_JP', 'コンテンツを学びたいという気持ち', '', ''),
    new SoleText('session.observation.vocabulary', 'ja_JP', '適切な語彙の使用', '', ''),
    new SoleText('session.observation.help_learn', 'ja_JP', '生徒が生徒に教える', 'レッスンの効果', ''),
    new SoleText('session.observation.help_technology', 'ja_JP', 'テクノロジーで仲間を助ける', '', ''),
    new SoleText('session.observation.keyboard', 'ja_JP', 'キーボードをうまく使えない', '', ''),
    new SoleText('session.observation.writing', 'ja_JP', '文章を書くのが苦手', '', ''),
    new SoleText('session.observation.reading', 'ja_JP', '読解が苦手', '', ''),
    new SoleText('session.observation.turns', 'ja_JP', '順番を守らない', '', ''),
    new SoleText('session.observation.imbalanced', 'ja_JP', '不均衡なグループ', '', ''),
    new SoleText('session.observation.plagiarizing', 'ja_JP', '情報の盗用', '', ''),
    new SoleText('session.observation.one_source', 'ja_JP', '1つのソースのみを使用', '', ''),
    new SoleText('session.observation.complaining', 'ja_JP', '不満を言う', '', ''),
    new SoleText('session.observation.off_task', 'ja_JP', 'オフタスク', '', ''),
    new SoleText('session.observation.inaccurate', 'ja_JP', '不正確な情報や無関係な情報', '', ''),
    new SoleText('session.observation.ohmeansjobs_collaborating', 'ja_JP', 'チームワーク/コラボレーション', '', ''),
    new SoleText('session.observation.ohmeansjobs_critical_thinking', 'ja_JP', 'クリティカルシンキング／問題解決', '', ''),
    new SoleText('session.observation.ohmeansjobs_leadership', 'ja_JP', 'リーダーシップ', '', ''),
    new SoleText('session.observation.ohmeansjobs_creativity', 'ja_JP', '創造性・革新性', '', ''),
    new SoleText('session.observation.ohmeansjobs_communication', 'ja_JP', 'Oral/Written Communication', '', ''),
    new SoleText('session.observation.ohmeansjobs_intercultural_fluency', 'ja_JP', 'グローバル/インターカルチュラル・フルエンシー', '', ''),
    new SoleText('session.observation.engagement', 'ja_JP', 'エンゲージメント', '', ''),
    new SoleText('session.observation.other', 'ja_JP', 'その他の観測', '', ''),
    new SoleText('session.observation.group.presentation', 'ja_JP', 'グループプレゼンテーション', 'グループプレゼンテーション', ''),
    new SoleText('reflection.type_of_thinking.recall', 'ja_JP', 'リコール＆リプロダクション', '', ''),
    new SoleText('reflection.type_of_thinking.skill', 'ja_JP', 'スキル、概念', '', ''),
    new SoleText('reflection.type_of_thinking.short_strategic', 'ja_JP', '短期的な戦略', '', ''),
    new SoleText('reflection.type_of_thinking.long_strategic', 'ja_JP', '長期的な戦略', '', ''),
    new SoleText('reflectionReminders', 'ja_JP', '振り返りのリマインダー', '', ''),
    new SoleText('planningDoc', 'ja_JP', '企画書', '', ''),
    new SoleText('summaryDoc', 'ja_JP', '概要資料', '', ''),
    new SoleText('ceuDoc', 'ja_JP', 'CEUドキュメント', '', ''),
    new SoleText('questionTips', 'ja_JP', '問いのヒント', '', ''),
    new SoleText('virtualEnded', 'ja_JP', 'バーチャルSOLEが終了しました', '', ''),
    new SoleText('virtualRecordings', 'ja_JP', 'バーチャルSOLEの録画', '', ''),
    new SoleText('user.role.teacher', 'ja_JP', '先生', '', ''),
    new SoleText('user.role.preservice_teacher', 'ja_JP', '教育実習生', '', ''),
    new SoleText('user.role.administrator', 'ja_JP', '管理者', '', ''),
    new SoleText('user.role.out_of_school_educator', 'ja_JP', '外部の先生', '', ''),
    new SoleText('user.role.coach', 'ja_JP', 'コーチ', '', ''),
    new SoleText('user.role.parent', 'ja_JP', '保護者', '', ''),
    new SoleText('user.role.other', 'ja_JP', 'その他', '', ''),
    new SoleText('session.target_observations_set.default', 'ja_JP', 'デフォルトの観察', '', ''),
    new SoleText('session.target_observations_set.ohiomeansjobs', 'ja_JP', '観察1', '', ''),
    new SoleText('session.target_observations_set.xyz', 'ja_JP', '観察2', '', ''),
    new SoleText('session.planned_duration', 'ja_JP', 'セッションの長さ', 'セッションの長さは？', ''),
    new SoleText('session.num_devices', 'ja_JP', 'デバイス', '利用可能なデバイスはいくつありますか？', 'インターネットを利用可能なデバイスはいくつありますか？'),
    new SoleText('session.num_students', 'ja_JP', '生徒', '生徒数は？', 'この SOLE セッションには、何名の生徒が参加しますか？'),
    new SoleText('session.num_groups', 'ja_JP', 'グループ', 'この SOLE セッションでは、いくつのグループを作りますか？', 'グループ数'),
    new SoleText('session.grade', 'ja_JP', '学年', 'このSOLEは何年生対象ですか？', ''),
    new SoleText('session.subject', 'ja_JP', '主題', 'SOLEの主題は何ですか？', ''),
    new SoleText('session.content_objective', 'ja_JP', 'コンテンツのねらい', 'このSOLEのねらいは何でしょうか？', 'このSOLEのねらいは何でしょうか？'),
    new SoleText('session.materials', 'ja_JP', 'マテリアル', 'このSOLEではどのようなマテリアルを使用しますか？', ''),
    new SoleText('session.grouporganization', 'ja_JP', '', '生徒は自分たちでグループを作りますか？', ''),
    new SoleText('session.groupsharing', 'ja_JP', '', '探究中のグループ間での共有を許可するか？', ''),
    new SoleText('session.self_assessment', 'ja_JP', '', '学生はSOLEの後に終了チケットを記入するのでしょうか？', ''),
    new SoleText('session.target_observations', 'ja_JP', '', 'このSOLEでの生徒たちの行動で、注目したいことを3つまで選んでください。', ''),
    new SoleText('session.reflection.type_of_thinking', 'ja_JP', '思考のタイプ', 'この SOLE セッションでは、生徒たちはどの思考のタイプを使っていましたか？', '')
];
