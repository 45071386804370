import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';
import Login from "./LoginComponent";
import Home from "./HomeComponent";
import HowToSole from "./how-to/HowToSoleComponent";
import Soles from "./sole/common/SolesComponent";
import Logout from "./LogoutComponent";
import QuestionAdd from "./question/QuestionAddComponent";
import QuestionsSearch from "./question/QuestionsSearch";
import Register from "./RegisterComponent";
import CssBaseline from "@material-ui/core/CssBaseline";
import {UserPub} from "../shared/soleTypes";
import ProfileComplete from "./profile/ProfileCompleteComponent";
import Profile from "./profile/ProfileComponent";
import HistoryOfSole from './history/HistoryComponent';
import Virtual from "./virtual/VirtualComponent";
import Summary from "./sole/common/SummaryComponent";
import {makeStyles} from "@material-ui/core/styles";
import ResponsiveDrawer from "./ResponsiveDrawerComponent";
import Resources from "./ResourcesComponent";
import QuestionsMine from "./question/QuestionsMine";
import Admin from "./admin/Admin";
import Dashboard from "./admin/Dashboard";
import Copy from './sole/common/CopyComponent';
import SoleApproval from "./admin/SoleApproval";
import MaintenancePopup from "./Maintenance";
import CommunityHome from "./community/CommunityHome";
import LakeErieTrainings from './community/LakeErieTrainings';
import Training from "./community/trainings/Training";
import QuestionEdit from "./question/QuestionEdit";
import QuestionApprovalBatch from './admin/QuestionApprovalBatch';
import PlanId from "./sole/PlanId";
import PlanState from "./sole/PlanState";
import QuestionsExplore from './question/QuestionsExplore';
import LoginWithToken from "./LoginWithTokenComponent";
import {HidableComponent} from "./hoc/Hidable";
import * as queryString from "querystring";
import ReflectId from "./sole/ReflectId";
import SingleQuestionId from "./question/SingleQuestionId";
import SingleQuestionState from './question/SingleQuestionState';
import Search from "./search/Search";
import SearchWelcome from "./search/SearchWelcomeComponent";
import SearchTeacher from "./search/SearchTeacher";
import TokenInput from './search/TokenInput';
import {useAppSelector} from "../redux/reduxHooks";
import {selectPub} from "../redux/userSlice";
import RoleRoute from './RoleRoute';

import {isMobile} from "react-device-detect";
import {isEmbedded} from "../embedded";
// const isEmbedded = () => true;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(isMobile ? 3 : 6)
    }

}));

const Main: React.FC = () => {
    const classes = useStyles();
    const pub = useAppSelector(selectPub);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        // TODO: can we intercept these messages somewhere higher up?
        const receivedMessage = (msg: MessageEvent) => {
            if (typeof msg.data === 'string' && msg.data?.startsWith("route:/")) {
                const route = msg.data.substr("route:".length);
                history.push(route);
            }
        };
        window.addEventListener('message', receivedMessage);
        return () => {
            window.removeEventListener('message', receivedMessage);
        };
    }, []);


    if (location.pathname.includes("/preload")) {
        const parms = queryString.parse(location.search.replace("?", ""));
        const url = parms["url"] as string;
        return <div><a href={url}>{url}</a></div>
    } else if (location.pathname.includes("/become")) {
        return <Route path="/become/:token" component={LoginWithToken}/>
    } else if (location.pathname === '/logout') {
        return <Logout/>
    } else if (pub !== undefined) {
        const pubObject = new UserPub(pub);
        if (!pubObject.isComplete) {
            return <ProfileComplete/>;
        } else {
            return (<div className={classes.root}>
                <CssBaseline/>
                {/* <MiniDrawer/>*/}
                <HidableComponent isVisible={!isEmbedded()}>
                    <ResponsiveDrawer/>
                </HidableComponent>
                <main className={classes.content}>
                    <HidableComponent isVisible={!isEmbedded()}>
                        <div className={classes.toolbar}/>
                        <MaintenancePopup/>
                    </HidableComponent>
                    <Switch>
                        <Route path="/home" component={Home}/>
                        <Route path="/how" component={HowToSole}/>
                        <Route path="/history" component={HistoryOfSole}/>
                        <Route path="/profile" component={Profile}/>
                        {/* <Route path="/profilecomplete" component={ProfileComplete}/>*/}
                        <Route exact path="/become/:token" component={LoginWithToken}/>
                        <Route exact path="/soles" component={Soles}/>
                        <Route exact path="/soles/plan" component={PlanState}/>
                        <Route exact path="/soles/virtual" component={Virtual}/>
                        <Route exact path="/soles/:id/plan" component={PlanId}/>
                        <Route exact path="/soles/:id/copy" component={Copy}/>
                        <Route exact path="/soles/:id/reflect" component={ReflectId}/>
                        <Route exact path="/soles/:id/virtual" component={Virtual}/>
                        <Route exact path="/soles/:id" component={Summary}/>
                        <Route path="/questions/new" component={QuestionAdd}/>
                        <Route exact path="/questions"><Redirect to="/questions/search"/></Route>
                        <Route path="/questions/mine" component={QuestionsMine}/>
                        <Route path="/questions/search" component={QuestionsSearch}/>
                        <Route path="/questions/explore" component={QuestionsExplore}/>
                        <RoleRoute exact path="/questions/:id/edit" role="mentor" component={QuestionEdit}/>
                        <Route path="/questions/:id" component={SingleQuestionId}/>
                        <Route path="/question" component={SingleQuestionState}/>
                        <Route path="/resources" component={Resources}/>
                        <Route exact path="/community/:ringRdn" component={CommunityHome}/>
                        <Route path="/community/:ringRdn/training" component={LakeErieTrainings}/>
                        <Route path="/training/:id" component={Training}/>
                        <RoleRoute exact path="/search-welcome" role="tester_search" component={SearchWelcome}/>
                        <RoleRoute path="/search-teacher" role="tester_search" component={SearchTeacher}/>
                        <RoleRoute path="/search" role="tester_search" component={Search}/>
                        <RoleRoute exact path="/admin" role="ringleader" component={Admin}/>
                        <RoleRoute exact path="/admin/approval/questions" role="super_ringleader" component={QuestionApprovalBatch}/>
                        <RoleRoute exact path="/admin/approval" role="super_ringleader" component={SoleApproval}/>
                        <RoleRoute path="/rings/:id" role="ringleader" component={Dashboard}/>
                        <Route path="/logout" component={Logout}/>
                        <Redirect to="/home"/>
                    </Switch>
                </main>
            </div>)
        }
    } else {
        return (<Switch>
            <Route path="/login" component={Login}/>
            <Route path="/register" component={Register}/>
            <Route path="/search" component={Search}/>
            <Route path="/join" component={TokenInput}/>
            <Redirect to="/login"/>
        </Switch>)
    }
};


export default Main;
