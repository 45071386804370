import React, {useCallback, useEffect, useRef} from 'react';
import {Tag} from "../../data/Tag";
import SelectionComponent from "../input/SelectionComponent";
import {SelectionConstraints} from "../../data/constraints/Constraints";
import {Box, Grid} from '@material-ui/core';
import {pickerStateIsEqual} from "./StandardsHelper";
import useStandardPickerSessionContext from "./UseStandardPickerSessionContext";
import useStandardPickerState from "./UseStandardPickerState";
import {useTranslationLabel} from "../../hooks/useTranslation";

export const allRdn = 'all';

export interface StandardPickerState {
    subjects: Tag[];
    selectedSubjectRdn?: string;

    grades: Tag[];
    selectedGradeRdn?: string;

    tags: Tag[][];
    selectedTagRdns: string[];
}

export const initialStandardPickerState: StandardPickerState = {
    subjects: [],
    selectedSubjectRdn: allRdn,

    grades: [],
    selectedGradeRdn: allRdn,

    tags: [],
    selectedTagRdns: []
};

export function getTags(pickerState: StandardPickerState): string[] {
    let tags: string[] = [];
    if (pickerState.selectedSubjectRdn) {
        tags.push(pickerState.selectedSubjectRdn);
    }
    if (pickerState.selectedGradeRdn) {
        tags.push(pickerState.selectedGradeRdn);
    }
    pickerState.selectedTagRdns.forEach((rdn) => {
        tags.push(rdn);
    });
    tags = tags.filter((tag) => tag !== allRdn);
    return tags;
}

interface StandardPickerProps {
    onChange: (pickerState: StandardPickerState) => void;
    onlyStandardsWithQuestions: boolean;
    hasSessionContext: boolean;
    standardPickerState?: StandardPickerState;
}

const StandardPicker: React.FC<StandardPickerProps> = (props) => {
    const [pickerState, selectSubject, selectGrade, selectTag, select] = useStandardPickerState(props.onlyStandardsWithQuestions, props.standardPickerState);
    const [setSessionContextState] = useStandardPickerSessionContext(selectSubject, selectGrade, selectTag);
    const prevPickerState = useRef<StandardPickerState>();
    const getLabel = useTranslationLabel();

    useEffect(() => {
        if (props.hasSessionContext) {
            setSessionContextState(pickerState);
        }
        if (!pickerStateIsEqual(pickerState, prevPickerState.current)) {
            prevPickerState.current = {...pickerState, selectedTagRdns:{...pickerState.selectedTagRdns}};
            props.onChange(pickerState);
        }
    }, [pickerState]);

    useEffect(() => {
        select(props.standardPickerState?.selectedSubjectRdn, props.standardPickerState?.selectedGradeRdn, props.standardPickerState?.selectedTagRdns);
    }, [props.standardPickerState]);

    const lngArray = window.navigator.language.split('-');
    let userSpeaksSpanish = false;
    if (lngArray[0] === 'es') {
        userSpeaksSpanish = true;
    }

    // Subjects
    const subjectConstraint = new SelectionConstraints();
    subjectConstraint.min = 1;
    subjectConstraint.max = 1;
    subjectConstraint.items = pickerState.subjects
        .map((subject) => {
            return {rdn: subject.rdn, title: subject.short}
        });

    const onChangeSubject = useCallback((value: string | string[] | undefined) => {
        if (typeof value === 'string') {
            selectSubject(value as string);
        }
    }, [selectSubject]);

    const renderedSubjects = pickerState.subjects ?
        (<SelectionComponent
            rdn="picker-subjects"
            onChange={onChangeSubject}
            constraint={subjectConstraint}
            initialValue={pickerState.selectedSubjectRdn}/>) :
        <div/>;

    // Grades
    const gradeConstraint = new SelectionConstraints();
    gradeConstraint.min = 1;
    gradeConstraint.max = 1;
    gradeConstraint.items = pickerState.grades
        .map((grade) => {
            return {rdn: grade.rdn, title: getLabel(grade.rdn)}
        });

    const onChangeGrade = (value: string | string[] | undefined) => {
        if (typeof value === 'string') {
            selectGrade(value as string);
        }
    };

    const renderedGrades = pickerState.grades ?
        (<SelectionComponent
            rdn="picker-grades"
            onChange={onChangeGrade}
            constraint={gradeConstraint}
            initialValue={pickerState.selectedGradeRdn}/>) :
        <div/>;

    // Tags
    const onChangeTag = (index: number, value: string | string[] | undefined) => {
        if (typeof value === 'string') {
            selectTag(index, value);
        }
    };

    const renderedTags = pickerState.tags.length > 0 ?
        pickerState.tags.map((tags, index) => {
            const tagConstraint = new SelectionConstraints();
            tagConstraint.min = 0;
            tagConstraint.max = 1;
            tagConstraint.items = tags
                .map((tag) => {
                    return {rdn: tag.rdn, title: tag.short}
                });

            return (<Grid key={`picker-tag-grid-${index}`} item xs={12} md={6}>
                <SelectionComponent
                    key={`picker-tag-${index}`}
                    rdn={`picker-tag-${index}`}
                    onChange={(value) => {
                        onChangeTag(index, value)
                    }}
                    constraint={tagConstraint}
                    initialValue={pickerState.selectedTagRdns[index]}
                />
            </Grid>)
        }) :
        <div/>;

    return (<Box mb={5}>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                {renderedSubjects}
            </Grid>
            <Grid item xs={12} md={6}>
                {renderedGrades}
            </Grid>
            {userSpeaksSpanish ? <></> : renderedTags}
        </Grid>
    </Box>)
};

export default StandardPicker;
