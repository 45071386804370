import React, {useState} from 'react';
import {Box, Button, Card, CardActions, CardContent, CardMedia, Grid, makeStyles, Typography} from "@material-ui/core";
import AutoRotatingCarouselModal from "./AutoRotatingCarouselModal";
import JoyrideTour from "./Joyride";
import {useTranslationLabel} from "../hooks/useTranslation";
import {Link as RouterLink} from "react-router-dom";
import {getRingProps} from "../theme/RingProps";
import DemoAcountSelector from "./DemoSelector";
import demoAccounts from "../shared/demoAccounts";
import {useAppSelector} from "../redux/reduxHooks";
import {selectPub} from "../redux/userSlice";

const useStyles = makeStyles((theme) => ({
    marginAutoContainer: {
        height: `calc(80vh - ${theme.spacing(6)}px - ${theme.mixins.toolbar.minHeight}px - 8px)`
    },
    marginAutoItem: {
        margin: 'auto'
    },
    maxWidthCard: {
      maxWidth: '400px',
        margin: 'auto'
    },
    media: {
        objectFit: 'none',
        height:`calc(${theme.spacing(4)}px + 64px)`,
        padding: theme.spacing(2)
    }
}));

const Home: React.FC = () => {
    const classes = useStyles();
    const getLabel = useTranslationLabel();
    const pub = useAppSelector(selectPub);
    const tags = useAppSelector((state) => state.tags.tags);
    if (pub){
        const ringProps = getRingProps(pub);
    }
    let showCarousel = true;
    if (pub?.points){
        showCarousel = pub?.points < 10;
    }

    const [tourStart, setTourStart] = useState(false);
    const isDemo = demoAccounts.find((account) => account.userPub === pub?.id);

    // TODO: make sure points are updated in AppState so the carousel is hidden appropriately
    return (<Grid container className={classes.marginAutoContainer} justifyContent="center">
        <Grid item xs={12} sm={12} className={classes.marginAutoItem}>
            {showCarousel ? <AutoRotatingCarouselModal/> : ''}
            {tourStart && <JoyrideTour start={tourStart} onClose={() => setTourStart(false)}/>}
            {/* <JoyrideTour start={tourStart} onClose={() => setTourStart(false)}/>*/}

            <Card variant="outlined" className={classes.maxWidthCard}>
                {/* <CardActionArea>*/}
                    <CardMedia className={classes.media} component="img" image="/images/logos/logo.svg"/>
                    <CardContent>
                        <Typography gutterBottom variant="h5" align="center">
                            {getLabel('ui.home.lets_get_started')}
                        </Typography>
                        {/* <Typography variant="body2" color="textSecondary" component="p">*/}
                            {/* {getLabel('ui.home.welcome_card_text')}*/}
                        {/* </Typography>*/}
                        <Box width="100%" display="flex">
                            <Box m="auto">
                                <Button component={RouterLink} to="/soles/plan"
                                        variant="contained" color="primary" size="large">
                                    {getLabel('ui.plan_a_lesson')}
                                </Button>
                            </Box>
                        </Box>
                    </CardContent>
                {/* </CardActionArea>*/}
                <CardActions>
                    <Grid container justifyContent="center" alignItems="center" spacing={3}>
                        <Grid item>
                            <Button size="small" component={RouterLink} to="/soles">{getLabel('ui.my_soles')}</Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" component={RouterLink} to="/questions/search">{getLabel('ui.explore_questions')}</Button>
                        </Grid>
                        {/* <Hidden smDown>*/}
                        {/* <Grid item>*/}
                        {/*    <Button size={'small'} onClick={() => setTourStart(true)}>*/}
                        {/*        {getLabel('ui.home.start_tour')}*/}
                        {/*    </Button>*/}
                        {/* </Grid>*/}
                        {/* </Hidden>*/}
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
        {isDemo ? <Grid item><DemoAcountSelector/></Grid> : <></>}
    </Grid>)
};

export default Home;
