import React from "react";
import {SoleProps} from "../common/SoleProps";
import BooleanComponent from "../../input/BooleanComponent";
import {useTranslationShort} from "../../../hooks/useTranslation";
import {BooleanConstraints} from "../../../data/constraints/Constraints";

interface SoleBooleanProps extends SoleProps {
    radio?: boolean;
    constraint?: BooleanConstraints;
}

const SoleBooleanComponent: React.FC<SoleBooleanProps> = (props) => {
    const getShort = useTranslationShort();
    const rdn = props.rdn;
    const jsonKey = props.jsonKey;
    const sole = props.sole;
    const label = props.label ?? getShort(rdn) ?? '';
    // @ts-ignore
    const initialValue = (sole && sole[jsonKey] !== undefined) ? sole[jsonKey] as boolean : undefined;

    return (<BooleanComponent
        initialValue={initialValue}
        label={label}
        rdn={rdn}
        onChange={
            (value: boolean) => {
                props.setSole({
                    ...props.sole,
                    [jsonKey]: value
                });
            }
        }
        radio={props.radio ?? false}
        constraint={props.constraint}
    />)
};

export default SoleBooleanComponent;
