import React from "react";
import {createStyles, Grid, Typography} from "@material-ui/core";
import {useTranslationLabel} from "../../../hooks/useTranslation";
import {Props, ValidatePart} from "./00_PartProps";
import SoleSelectionComponent from "../primitives/SoleSelectionComponent";
import SoleStringComponent from "../primitives/SoleStringComponent";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        marginBottom: {
            marginBottom: theme.spacing(5),
            marginTop: '-20px'
        }
    })
);

export const validateReflectObjectivesPart:ValidatePart = ({sole, getLabel}) => {
    if (sole.contentObjectiveRdn === undefined) {
        return getLabel('ui.reflect.missing_content_objective');
    } else if (sole.reflectionContentObjectiveAchievedRdn === undefined) {
        return getLabel('ui.reflect.missing_content_objective_achieved');
    } else if (sole.reflectionTypeOfThinkingRdn === undefined) {
        return getLabel('ui.reflect.missing_type_of_thinkin');
    }
    return null;
};

const ReflectObjectivesPart: React.FC<Props> = ({props}) => {

    const getLabel = useTranslationLabel();
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={12}>
                <SoleSelectionComponent
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="contentObjectiveRdn"
                    rdn="session.content_objective"
                />
            </Grid>
            <Grid item xs={12}>
                <div style={{height:"3em"}}/>
            </Grid>
            <Grid item xs={12}>
                <SoleSelectionComponent
                    label={getLabel('ui.reflect.objective_question_achieved')}
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="reflectionContentObjectiveAchievedRdn"
                    rdn="session.reflection.content_objective.achieved"
                />
            </Grid>
            <Grid item xs={12} className={classes.marginBottom}>
                <SoleStringComponent
                    label={getLabel('ui.reflect_why_do_you_think_this')}
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="reflectionContentObjectiveNotes"
                    rdn="session.reflection.content_objective.notes"
                />
            </Grid>
            <Grid item xs={12}>
                <div style={{height:"1.5em"}}/>
            </Grid>
            <Grid item xs={12}>
                <SoleSelectionComponent
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="reflectionTypeOfThinkingRdn"
                    rdn="session.reflection.type_of_thinking"
                />
            </Grid>
            <Grid item xs={12} className={classes.marginBottom}>
                <SoleStringComponent
                    label={getLabel('ui.reflect_why_do_you_think_this')}
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="reflectionTypeOfThinkingNotes"
                    rdn="session.reflection.type_of_thinking.notes"
                />
            </Grid>
        </Grid>
    );

};

export default ReflectObjectivesPart;
