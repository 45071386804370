import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    Container,
    createStyles,
    Grid,
    Step,
    StepButton,
    StepLabel,
    Stepper, Typography
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useTranslationLabel} from "../../../hooks/useTranslation";
import {OnSoleChanged, PartProps} from "../parts/00_PartProps";
import {OnSaveHandler} from "./SoleSaver";
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import {isMobile} from "react-device-detect";
import {ISole} from "../../../shared/soleTypes";
import {createHash} from "crypto";
import {HidableComponent} from "../../hoc/Hidable";

import {isEmbedded} from "../../../embedded";
// const isEmbedded = () => true;

export interface SoleStep {
    label: (sole:ISole) => string|undefined;
    content: (props:PartProps) => JSX.Element;
    validate: (props:PartProps) => string|null;
}

interface SoleStepperProps {
    steps: SoleStep[];
    props: PartProps;
    errorMsg: string|undefined;
    onSave: OnSaveHandler;
}

const useStyles = makeStyles((theme) =>
    createStyles({ // desktop
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1)
        },
        root: {
            maxWidth: 400,
            flexGrow: 1
        },
        footer: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: '#f5f5f5',
            zIndex: 100
        },
        content: {
            marginBottom: '48px'
        }
    })
);

const SoleStepper: React.FC<SoleStepperProps> = ({steps, props, errorMsg, onSave}) => {
    const theme = useTheme();
    const classes = useStyles();
    const getLabel = useTranslationLabel();

    const [activeStep, setActiveStep] = useState(0);
    const totalSteps = steps.length;
    const isFirstStep = activeStep === 0;
    const isLastStep = activeStep === steps.length - 1;
    const helpText = steps[activeStep].validate(props);
    const isCompleted = helpText === null;
    const allStepsCompleted = !steps.some((step) => step.validate(props) !== null);

    // check if the step is complete and force a render when the 'isComplete' state changed
    const activeStepRef = useRef(activeStep);
    useEffect(() => {
        activeStepRef.current = activeStep;
    }, [activeStep]);
    const isCompletedRef = useRef(new Array(steps.length));
    const [, setForceRender] = useState(0);
    useEffect(() => {
        // note: below callback gets initialized on start so use refs to access state
        const onSoleChanged:OnSoleChanged = (_newSole, _prevSole) => {
            const currentStep = activeStepRef.current;
            const didComplete = steps[currentStep].validate(props) === null;
            if (didComplete !== isCompletedRef.current[currentStep]) {
                isCompletedRef.current[currentStep] = didComplete;
            }
            setForceRender((current) => current + 1);
        };
        props.setOnSoleChanged(onSoleChanged, true);
        return () => {
            props.setOnSoleChanged(onSoleChanged, false);
        };
    }, []);

    const handleNext = () => {
        const newActiveStep =
            isLastStep && !allStepsCompleted
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                steps.findIndex((step) => step.validate(props) === null)
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSave = () => {
        onSave(props.sole);
    };

    const getKey = (step:SoleStep, index:number) => {
        return createHash("sha1").update(`${step.label(props.sole)}-${index}`).digest('base64');
    };

    const getHelp = ():string|undefined => {
        if (helpText !== null) {
            return helpText; // optionally set by Step
        } else if (errorMsg) {
            return errorMsg; // optionally set by Part
        }
        return undefined;
    };

    const desktopStepper = (
        <Container maxWidth="md">
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((step, index) =>
                    (<Step key={getKey(step, index)}>
                        <StepButton onClick={() => setActiveStep(index)} completed={step.validate(props) === null}>
                            <StepLabel error={step.validate(props) !== null && index < activeStep}>{step.label(props.sole) ?? ""}</StepLabel>
                        </StepButton>
                    </Step>)
                )}
            </Stepper>
            <div>
                {steps[activeStep].content(props)}
                <Box mt={10}>
                    <Grid container alignItems="center" direction="column">
                        {getHelp() && <Grid item xs={6} sm={4}>
                            <Alert severity="info">{getHelp()}</Alert>
                        </Grid>}
                        <Grid item>
                            {!isFirstStep &&
                                <Button onClick={handleBack} className={classes.button}>
                                    {getLabel('ui.back')}
                                </Button>
                            }
                            {!isLastStep ?
                                <Button
                                    disabled={!isCompleted}
                                    variant={allStepsCompleted ? undefined : "contained"}
                                    color={allStepsCompleted ? undefined : "primary"}
                                    onClick={handleNext}
                                    className={classes.button}>
                                    {getLabel('ui.next')}
                                </Button> :
                                <Button
                                    disabled={!allStepsCompleted}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                    className={classes.button}>
                                    {getLabel('ui.save')}
                                </Button>}
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </Container>
    );

    const mobileStepper = (<>
        <HidableComponent isVisible={isEmbedded()}>
            <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{minHeight: '44pt'}}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                        {steps[activeStep].label(props.sole) ?? ""}
                    </Typography>
                </Grid>
            </Grid>
        </HidableComponent>
        <Grid justify="center">
            {steps[activeStep].content(props)}
        </Grid>
        <MobileStepper
            variant="dots"
            steps={totalSteps}
            position="static"
            activeStep={activeStep}
            className={classes.footer}
            nextButton={
                isLastStep ? <Button
                        disabled={!isCompleted}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleSave}>
                        {getLabel('ui.save')}
                    </Button> :
                    <Button
                        disabled={!isCompleted}
                        size="small"
                        onClick={handleNext}>
                        {getLabel('ui.next')}
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                    </Button>
            }
            backButton={ <Button
                        disabled={isFirstStep}
                        size="small"
                        onClick={handleBack}
                        style={isFirstStep ? {visibility:"hidden"} : {}}
                        >
                        {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                        Back
                    </Button>
            }
        />
    </>);

    return isMobile ? mobileStepper : desktopStepper;

};

export default SoleStepper;
