import React, {useState} from 'react';
import {AutoRotatingCarousel, Slide} from "material-auto-rotating-carousel";
import {teal} from "@material-ui/core/colors";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {getRingProps} from "../theme/RingProps";
import {useAppSelector} from "../redux/reduxHooks";
import {selectPub} from "../redux/userSlice";

const AutoRotatingCarouselModal: React.FC = () => {

    const [handleOpen, setHandleOpen] = useState({open: true});
    const pub = useAppSelector(selectPub);
    const mobile = useMediaQuery("(max-width:600px)");
    const customSlides = pub ? getRingProps(pub).customSlides ?? [] : [];
    const slides = [
        <Slide
            key="slide-0"
            media={
                <img src="/images/carousel/slide-1.png" alt=""/>
            }
            mediaBackgroundStyle={{backgroundColor: teal[50]}}
            style={{backgroundColor: teal[600]}}
            title="Let Learning Happen"
            subtitle="StartSOLE is a simple tool to help you plan, facilitate, and reflect on engaging inquiry experiences."
        />, <Slide
            key="slide-1"
            media={
                <img src="/images/carousel/slide-2.png" alt=""/>
            }
            mediaBackgroundStyle={{backgroundColor: teal[50]}}
            style={{backgroundColor: teal[600]}}
            title="Support your Process"
            subtitle="Explore 15,000+ standards-aligned essential questions and create a lesson plan in minutes."
            landscape
        />, <Slide
            key="slide-1"
            media={
                <img src="/images/carousel/slide-3.png" alt=""/>
            }
            mediaBackgroundStyle={{backgroundColor: teal[50]}}
            style={{backgroundColor: teal[600]}}
            title="Help is on the Way!"
            subtitle="Reflect on your experience and get personalized help while earning certificates for SOLE"
        />].concat(customSlides);

    return (
        <AutoRotatingCarousel
            label="Get started"
            open={handleOpen.open}
            onClose={() => setHandleOpen({open: false})}
            onStart={() => setHandleOpen({open: false})}
            // autoplay={false}
            interval={7000}
            mobile={mobile}
            style={{position: "absolute"}}
        >
            {slides.map((slide, index) => {
                return slide;
            })}
        </AutoRotatingCarousel>
    );
};

export default AutoRotatingCarouselModal;

