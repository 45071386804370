import React, {useState} from "react";
import {BooleanConstraints} from "../../data/constraints/Constraints";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch, Typography} from "@material-ui/core";
import {isIOS} from 'react-device-detect';
import IOSSwitch from "./iOS/IOSSwitch";
import {useAppSelector} from "../../redux/reduxHooks";
import {selectConstraints} from "../../redux/constraintSlice";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslationLabel} from "../../hooks/useTranslation";

interface BooleanProps {
    initialValue?: boolean;
    label: string;
    rdn: string;
    onChange: (value: boolean) => void;
    radio?: boolean;
    constraint?: BooleanConstraints;
}

const useStyles = makeStyles({
    noLeftMargin: {
        marginLeft: '0px',
        width: '100%'
    },
    fullWidth: {
        width: '100%'
    }
});

const BooleanComponent: React.FC<BooleanProps> = (props) => {
    const [value, setValue] = useState<boolean | undefined>(props.initialValue);
    const constraints = useAppSelector(selectConstraints);
    const getLabel = useTranslationLabel();
    const classes = useStyles();

    const constraint = props.constraint ? props.constraint : props.rdn ? constraints.get(props.rdn) : undefined;
    if (constraint instanceof BooleanConstraints) {
        if (value === undefined && constraint.defaultValue !== undefined) {
            setValue(constraint.defaultValue);
        }
        const strValue = value === undefined ? undefined : (value ? "true" : "false");

        if (props.radio) {
            return (<FormControl component="fieldset">
                <FormLabel component="legend">{props.label}</FormLabel>
                <RadioGroup aria-label=""
                            name={props.rdn}
                            value={strValue}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const bool = (event.target as HTMLInputElement).value == "true";
                                setValue(bool);
                                props.onChange(bool);
                            }}>
                    <FormControlLabel value="true" control={<Radio/>} label={getLabel('ui.yes')}/>
                    <FormControlLabel value="false" control={<Radio/>} label={getLabel('ui.no')}/>
                </RadioGroup>
            </FormControl>);
        } else {
            return (<FormControlLabel
                className={classes.noLeftMargin}
                control={
                    isIOS ?
                        <IOSSwitch checked={value} onChange={(event, checked) => {
                            setValue(checked);
                            props.onChange(checked);
                        }} name={props.rdn}/> :
                        <Switch
                            checked={value}
                            onChange={(event, checked) => {
                                setValue(checked);
                                props.onChange(checked);
                            }}
                            name={props.rdn}
                            color="primary"
                        />
                }
                label={<Typography gutterBottom className={classes.fullWidth}>{props.label}</Typography>}
                labelPlacement="start"
            />);
        }
        // <Typography component="legend">{props.label}</Typography>
        // <Switch
        //     checked={value}
        //     onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        //         setValue(checked);
        //         props.onChange(checked);
        //     }}
        // />

    } else {
        return <div/>;
    }
};

export default BooleanComponent;
