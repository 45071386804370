import React from "react";
import {Grid} from "@material-ui/core";

import SoleSelectionComponent from "../primitives/SoleSelectionComponent";
import {Props, ValidatePart} from "./00_PartProps";

export const validateObjectivePart:ValidatePart = ({sole, getLabel}) => {
    if (sole.contentObjectiveRdn === undefined) {
        return getLabel('ui.plan.missing_logistics_objective');
    }
    return null;
};

export const ObjectivePart: React.FC<Props> = ({props}) => {
    return (
        <Grid item xs={12} sm={8}>
            <SoleSelectionComponent
                sole={props.sole}
                setSole={props.setSole}
                jsonKey="contentObjectiveRdn"
                rdn="session.content_objective"
            />
        </Grid>
    );
};
