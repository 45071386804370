import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useRef, useState} from "react";
import {useTranslationLabel} from "../../../hooks/useTranslation";
import {Props, ValidatePart} from "./00_PartProps";

function isValidQuestion(question:string):boolean {
    return question.trim().length > 2 && // at least 2 characters
           question.split(" ").length > 1; // at least 2 words
           // question.includes("?" || "¿"); // includes at least 1 question marks - who knows what questions are in every language?
}

export const validateQuestionPart:ValidatePart = ({sole, getLabel}) => {
    if (sole.question === undefined || sole.question.text === undefined || !isValidQuestion(sole.question.text)) {
        return getLabel('ui.plan.missing_question');
    }
    return null;
};

export const QuestionPart: React.FC<Props> = ({props}) => {
    const getLabel = useTranslationLabel();
    const [question, setQuestion] = useState(props.sole.question?.text ?? "");

    useEffect(() => {
        props.setSole({...props.sole, question: {text:question}});
        props.setQuestion(question);
    }, [question]);

    return (<Grid container justify="space-around" spacing={3}>
        <Grid item xs={12} sm={8}>
            <TextField
                label={getLabel('ui.plan.what_is_your_big_question')}
                value={question}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                multiline
                onChange={(e) => setQuestion(e.target.value)}
            />
        </Grid>
    </Grid>)
};

export default QuestionPart;
