import React from "react";
import {Grid} from "@material-ui/core";
import SoleNumberComponent from "../primitives/SoleNumberComponent";
import {NumberConstraints} from "../../../data/constraints/Constraints";
import {ValidatePart, Props} from "./00_PartProps";
import {isMobile} from "react-device-detect";

function isValid(value:number|undefined, constraint:NumberConstraints):boolean {
    return value === undefined || (value >= (constraint.min ?? -999999) && value <= (constraint.max ?? 999999));
}

export const validateClassDetailsGroup:ValidatePart = ({sole, constraints, getLabel}) => {
    if (!isValid(sole.numDevices, constraints.get('session.num_devices') as NumberConstraints) ||
        !isValid(sole.numStudents, constraints.get('session.num_students') as NumberConstraints) ||
        !isValid(sole.numGroups, constraints.get('session.num_groups') as NumberConstraints)) {
        return getLabel('ui.plan.missing_details');
    }
    return null;
};

export const ClassDetailsGroups: React.FC<Props> = ({props}) => {

    const xs = isMobile ? 12 : 5;

    return (<>

            <Grid item xs={xs}>
                <SoleNumberComponent
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="numStudents"
                    rdn="session.num_students"
                    isSlider={true}
                />
            </Grid>

            <Grid item xs={xs}>
                <SoleNumberComponent
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="numDevices"
                    rdn="session.num_devices"
                    isSlider={true}
                />
            </Grid>

            <Grid item xs={xs}>
                <SoleNumberComponent
                    sole={props.sole}
                    setSole={props.setSole}
                    jsonKey="numGroups"
                    rdn="session.num_groups"
                    isSlider={true}
                />
            </Grid>
        </>
    )
};
